import React,{useEffect} from 'react'
import '../assets/css/login.css';
import '../assets/css/responsive.css';
import { useState } from 'react';
import { Box, Button, FormControl, OutlinedInput, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { setEmail, setPassword, validateEmail, validatePassword, setEmailError, setPasswordError, } from './authSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CompanyLogo from '../assets/images/viable-logo.jpeg'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Loginus = () => {

	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const [sinupVisible, setSinupVisible] = useState(false);

	const handleShow = () => {
		setVisible(!visible);
	}

	const handleSinupShow = () => {
		setSinupVisible(!sinupVisible);
	}

	const toggleButton = (type) => {
		const container = document.getElementById('container');
		if (type === "signin") {
			container.classList.remove("right-panel-active");
		}
		if (type === "signup") {
			container.classList.add("right-panel-active");
		}
	}

	const dispatch = useDispatch();
	const { email, password, emailError, passwordError } = useSelector((state) => state.auth);

	const handleEmailChange = (e) => {
		dispatch(setEmail(e.target.value));
		dispatch(setEmailError(false));
	};

	const handlePasswordChange = (e) => {
		dispatch(setPassword(e.target.value));
		dispatch(setPasswordError(false));
	};



	const handleValidate = () => {


		let formIsValid = true;

		if (email === '') {
			formIsValid = false;
			dispatch(setEmailError(true));

		}
		if (password === '') {
			formIsValid = false;
			dispatch(setPasswordError(true));

		}
		return formIsValid;
	};

	const handleOnClick = async () => {
		if (handleValidate()) {
			let jsonData = {
				"username": email,
				"password": password
			}
			/*await axios.post("http://localhost:5000/login" ,jsonData).then((res) => {
				if(res?.data?.status === 200){
					navigate("/");
					//toast.success("Login Successful!")
				}else{
					//toast.error("Invalid Login");
				}
			}).catch(function (error) {
				console.log(error)
				
			})*/


			toast.success("Thank You For Sign In")
			setTimeout(() => {
				navigate("/dashboard");
			}, "500");

		}

	};

	
	  const loginTo = (event)=> {
		console.log(event.code);
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            handleOnClick();
        }
    }
	useEffect(() => {
		const listener = event => {
		  if (event.code === "Enter" || event.code === "NumpadEnter") {
			
			handleOnClick();
		  }
		};
		document.addEventListener("keydown", listener);
		return () => {
		  document.removeEventListener("keydown", listener);
		};
	  }, []);


	return (
		<>
			<Box component='section' className='login-body'>
				<Typography variant='' component='h2' className='main-heading'>Viable Integration Platform</Typography>
				<Box component='div' className='container' id='container'>
					<Box className='form-container sign-up-container'>
						<form className='form'>
							<Box sx={{ mb: 3 }}>
								{/* <Typography component='h1' variant='' className='sign-up-head'>Create Account</Typography> */}
								{/* <Box className='social-container'>
								<Link to='#' className='social'><i className="fa-brands fa-facebook-f" aria-hidden="true"></i></Link>
								<Link to='#' className='social'><i className="fa-brands fa-google"></i></Link>
								<Link to='#' className='social'><i className="fa-brands fa-linkedin-in"></i></Link>
							</Box> */}
								{/* <Typography component='span' variant='span' sx={{ my: 1 }}>or use your email for registration <Link to='#' className='sign-up-link' onClick={() => toggleButton("signin")}>&ensp; Sign In</Link></Typography> */}

							</Box>
							<Box>
								<FormControl sx={{ margin: '5px 5px' }} variant="outlined" fullWidth>
									<OutlinedInput placeholder='Name' className='input_field' />
								</FormControl>
								<FormControl variant="outlined" fullWidth sx={{ margin: '5px 5px' }}>
									<OutlinedInput
										placeholder='Email' className='input_field'
									/>
								</FormControl>
								<FormControl sx={{ margin: '5px 5px 5px 5px' }} variant="outlined" fullWidth>
									<OutlinedInput type={sinupVisible ? "text" : "password"} placeholder='Password' className='input_field' />
									<span className="eyeslash" onClick={handleSinupShow} >{!sinupVisible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
								</FormControl>
							</Box>


							<Button className="button" sx={{ mt: 2 }}>Register for 1st time</Button>
						</form>
					</Box>

					<Box className='form-container sign-in-container'>
						<form className='form'>
							<Box sx={{ mb: 5 }}>
								{/* <Typography component='h1' variant=''>Sign in</Typography> */}
								{/* <Box className='social-container'>
								<Link to='#'><i class="fa-brands fa-facebook-f" aria-hidden="true"></i></Link>
								<Link to='#'><i class="fa-brands fa-google" aria-hidden="true"></i></Link>
							</Box> */}
								{/* <Typography component='span' variant='span'>or use your account</Typography> */}

							</Box>
							<Box>
								<Box>
									<FormControl sx={{ margin: '5px 5px' }} className={emailError ? "error" : ""} variant="outlined" fullWidth>
										<OutlinedInput placeholder='Email' value={email} onChange={handleEmailChange} className='input_field' onKeyDown={(e) => loginTo(e) } />
									</FormControl>
									<FormControl fullWidth sx={{ margin: '5px 5px' }} className={passwordError ? "error" : ""} >
										<OutlinedInput type={visible ? "text" : "password"} placeholder="Password" value={password} onChange={handlePasswordChange} className='input_field' onKeyDown={(e) => loginTo(e) } />
										<span className="eyeslash" onClick={handleShow} >{!visible ? <i class="fa fa-eye-slash" aria-hidden="true"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}</span>
									</FormControl>
								</Box>

							</Box>

							<Box sx={{ my: 1 }}>
								<Link to='#'>Forgot your password?</Link>
								<Link to='#' className='sign-up-link' onClick={() => toggleButton("signup")}>&ensp; Register for 1st time</Link>
							</Box>

							<Button className="button" onClick={handleOnClick} sx={{marginTop:'20px'}}>Sign In</Button>
						</form>
					</Box>

					<Box className="overlay-container">
						<Box className="overlay">
							<Box className='overlay-panel overlay-left'>
								<img src={CompanyLogo} className='company_logo' />
								<Box >
									{/* <Typography variant='' component='h1' sx={{ color: '#fff' }}>Welcome Back!</Typography> */}
									<Typography variant='p' component='p' className='p' sx={{ my: 3 }}>To keep connected with us please login with your personal info</Typography>
									<Button className="button ghost sign-up" onClick={() => toggleButton("signin")} sx={{mt:3}}>Sign In</Button>

								</Box>
							</Box>
							<Box class="overlay-panel overlay-right">
								<img src={CompanyLogo} className='company_logo' />
								<Box >
									{/* <Typography variant='' component='h1' sx={{ color: '#fff' }}>Hello, Friend!</Typography> */}
									<Typography variant='p' component='p' className='p' sx={{ my: 3 }}>Enter your personal details and start journey with us</Typography>
									<Button className="button ghost sign-up" variant='contained' onClick={() => toggleButton("signup")}>Register for 1st time</Button>

								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<ToastContainer />
		</>
	)
}

export default Loginus