import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppStore } from '../../appStore';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import { Collapse } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const drawerWidth = 229;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  // const [open, updateOpen] = React.useState(true);
  const navigate = useNavigate();
  const updateOpen = useAppStore((state) => state.updateOpen);
  const open = useAppStore((state) => state.dopen);
  const [isCollapse, setIsCollapse] = React.useState(false);
  let location = useLocation();
  //console.log(location.pathname);
  const handleCollapse = (e) => {
    setIsCollapse(!isCollapse);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box height={30} />
      <Drawer className='border-no' variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton >
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className='list-area' sx={{mt:0,pt:0}}>

          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/dashboard'? 'active':''} onClick={() => { navigate("/dashboard") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <SpeedOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>
            
          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/sendpayment'? 'active':''} onClick={() => { navigate("/sendpayment") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <LocalAtmOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Send a Payment" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/send-multiple-payments'? 'active':''} onClick={() => { navigate("/send-multiple-payments") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <PaymentsOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Send Multiple Payments" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/approvepayments'? 'active':''} onClick={() => { navigate("/approvepayments") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <LibraryAddCheckOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Approve Payments" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/sendinvoice'? 'active':''} onClick={() => { navigate("/sendinvoice") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <ArticleOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Send an Invoice" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }}  className={location.pathname === '/send-multiple-invoices'? 'active':''} onClick={() => { navigate("/send-multiple-invoices") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <FileCopyOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Send Multiple Invoices" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate("#") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <ReceiptOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Billing" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/reports'? 'active':''} onClick={() => { navigate("/reports") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <ListAltOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} className={location.pathname === '/marketplace'? 'active':''} onClick={() => { navigate("/marketplace") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <ShareOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Marketplace" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>

          


            
          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate("#") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <CurrencyExchangeOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Currency Conversion" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate("#") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <DeviceHubOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Fusion of other Services" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
            </ListItemButton>
          </ListItem>


          <ListItem disablePadding sx={{ display: 'block' }} onClick={handleCollapse}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                <SettingsSuggestOutlinedIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} className='sidebar_text' />
              {isCollapse ? <ExpandLessOutlinedIcon />: <ExpandMoreOutlinedIcon/>}
            </ListItemButton>
          </ListItem>
         
          <Collapse in={isCollapse} timeout="auto" unamountOnExit>
          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate("/account-card") }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  color: '#0e2e7d',
                }}
              >
                
              </ListItemIcon>
              <ListItemText primary="Accounts and Cards" sx={{ opacity: open ? 1 : 0,paddingLeft:'20px' }} className='sidebar_text' />
          
            </ListItemButton>
          </ListItem>
          </Collapse>
        </List>
      </Drawer>
    </Box>
  );
}