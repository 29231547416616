import React from "react";
import "../../assets/css/custom.css";
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import loaderImg from '../../assets/images/site-icons.png';

const Loader = (props) => {
  console.log("display", props.display);
  return (
    <><div className="loader-file">
      <Box component="div" className="loaderDiv" style={{ display: (props.display?"flex":"none") }}>
        
        <div className="loader-compund">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>        
            <img className="loaderImg" src={loaderImg} />
        </div>
       
       
      </Box>
 </div>
    </>
  );
};

export default Loader;
