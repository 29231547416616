import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAppStore} from '../../appStore';
import HelpIcon from '@mui/icons-material/Help';
import '../../assets/css/custom.css';
import { Link } from '@mui/material'
import LogoIcon from '../../assets/images/dashboard_icon.svg';
import CompanyLogo from '../../assets/images/viable-logo.jpeg'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Divider from '@mui/material/Divider';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const AppBar = styled(MuiAppBar, {
}) (({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));



export default function Navbar() {
  const navigate=useNavigate();
  const logout = ()=>{
   return navigate('/');
 }



  const updateOpen = useAppStore((state)=>state.updateOpen);
  const dopen = useAppStore((state)=>state.dopen);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

 

  return (
    // navbar starts


    <Box sx={{ flexGrow: 1 }} className='top-header'>
      <AppBar position="fixed">
        <Toolbar>
            <Box>
              <img src={CompanyLogo} className='main-logo-head'/>
            </Box>
            <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 7}}
            onClick={()=>updateOpen(!dopen)}
          >
          <MenuIcon sx={{color:'#0e2e7d'}}/>
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              color="blue" sx={{color:'#0E2E7D'}}>
              <Badge  color="error">
                <NotificationsIcon sx={{fontSize:'20px'}}/>
                <Typography variant='span' className='notification-dot'></Typography>
              </Badge>
            </IconButton>
          
            <Box className='profile-area'  onClick={handleOpenUserMenu} sx={{cursor:'pointer'}}>
              <Typography variant='p' component='p' className='company'  >Viable LLC</Typography>
              <Typography variant='p'component='p' className='member-name'>viable.llc</Typography>
            </Box>
            <Menu
    sx={{ mt: '48px',py:0}}
    id="menu-appbar"
    anchorEl={anchorElUser}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={Boolean(anchorElUser)}
    onClose={handleCloseUserMenu}
      className='logout-menu'
    >
       <MenuItem sx={{borderBottom:'1px solid #d4d4d4'}}><PlayCircleOutlineIcon sx={{color:'#0E2E7D', fontSize:'16px', marginRight:'10px'}}/> Guided Tour</MenuItem>
      <MenuItem sx={{borderBottom:'1px solid #d4d4d4'}}><SettingsOutlinedIcon sx={{color:'#0E2E7D', fontSize:'16px', marginRight:'10px'}}/> Settings</MenuItem>
      <MenuItem  onClick={logout} textAlign="center" ><PowerSettingsNewIcon  sx={{color:'#0E2E7D', fontSize:'16px',marginRight:'10px'}}/>Logout</MenuItem>
    </Menu>
          </Box>
         
        </Toolbar>
      </AppBar>
    
    </Box>
  );
}