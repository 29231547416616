import { Box, Button, Container, Grid, List, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment/moment';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Loader from './Layouts/Loader.js'
import {CustomTabPanel,BootstrapDialog,StyledTabs,StyledTab,BootstrapTooltip} from './commonStyle/muiCustomStyle.js';
import currencyFormatter from 'currency-formatter';
import AllPaymentList from './allPaymentList.js';



const Marketplace = () => {
   

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const [userName, setUserName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userData, setUserData] = React.useState([]);
  const [transactionData, setTransactionData] = useState([]);
  
  const [display,setDisplay] = useState(false);

  const [page, setPage] = useState(1);
   const [pageUser, setPageUser] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
    const handleChangeUser = (event, value) => {
    setPageUser(value);
  };



  const onChangeUser = (e) => {
    setUserName(e.target.value);
  }
  const onChangeUserEmail = (e) => {
    setUserEmail(e.target.value);
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const handleUserOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleValid = () => {
    let formdata = true;
    if (!userName) {
      formdata = false;
    }
    if (!userEmail) {
      formdata = false;
    }
    return formdata;
  }


  const createNewUser = () => {
    if (handleValid()) {

      let data = JSON.stringify({
        name: userName,
        user_id: userEmail,
      });



      let config = {
        method: 'POST',
        url: 'https://sandbox.checkbook.io/v3/user',
        headers: {
          'accept': 'application/json',
          'Authorization': ' e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {

          if (response?.status === 201) {
            toast.success("User Added Sucessfully.")
            setOpen(false);
            getAllUsers();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("Please Fill the required field.")
    }

  }

  useEffect(() => {
    getTransactionData();
  }, []);


  useEffect(() => {
    getAllUsers();
  }, []);
  const [perPageData, setPerPageData] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [pageUserCount, setPageUserCount] = useState(0)

  const getAllUsers = () => {
    setDisplay(true)

    let config = {
      method: 'GET',
      url: `https://sandbox.checkbook.io/v3/user/list?page=${pageUser}&per_page=${perPageData}`,
      headers: {
        'accept': 'application/json',
        'Authorization': ' e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      },

    };

    axios.request(config)
      .then((response) => {
        setUserData(response.data.users);
        setPageUserCount(response.data?.pages)
        
      })
      .catch((error) => {
       
        console.log(error);
      });

  }

  const getTransactionData = async () => {
    setDisplay(true)

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://sandbox.checkbook.io/v3/check?page=${page}&per_page=${perPageData}`,
      headers: {
        'accept': 'application/json',
        'Authorization': ' e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {
        setTransactionData(response.data?.checks);
        setPageCount(response.data?.pages)
      })
      .catch((error) => {
        console.log(error);
      });

  }
  useEffect(() => {
    getTransactionData();
  },[page])
    useEffect(() => {
    getAllUsers();
  },[pageUser])

  
  useEffect(()=>{
    if(transactionData.length && userData.length){
       setDisplay(false) 
    }
  },[userData,transactionData])


  const addressFormatter = (address) => {
    if(address.hasOwnProperty('city')){
      return address?.line_1+(address?.line_2?', '+address?.line_1+', ':'')+(address?.city?', '+address?.city:'')+(address?.state?', '+address?.state:'')+(address?.zip?', '+address?.zip:'')
    }else{
      return address;
    }
  }




  return (
    <>
      <Navbar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
          <Box>
            <Box className='upper-bluearea'>
              <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
            </Box>

            <Box className='inner-section' display={display==false ? "block":"none"}>
              <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Marketplace</Typography>
              <Box className='incoming-funds'>
                <Typography variant='p' className='fund_head'>Acitvity this month</Typography>
                <Box className='dollarSection' sx={{ py: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                      <Typography>Payments</Typography>
                      <Typography variant='p' className='dollar_numbers'>0</Typography>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Typography>Invoices</Typography>
                      <Typography variant='p' className='dollar_numbers'>0</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box className='forever_table' >
                <Box className='dashboard_table padding-section'>
                  <Box sx={{ width: '100%', }}>
                    <Box >
                      <StyledTabs sx={{height:'50px',display:'flex',alignItems:'center'}} value={value} onChange={handleTabChange} aria-label="basic tabs example" className="indicator">                              
                     <StyledTab label="Transactions" {...a11yProps(0)} style={{color:'rgba(0, 0, 0, 0.87)', fontSize:'.875rem',display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<AccountBalanceWalletOutlinedIcon sx={{fontSize:'18px',color:'rgba(0, 0, 0, 0.87)'}}/>} className='tabs_style '  iconPosition="start"  />
                      <StyledTab label="Users" {...a11yProps(1)} style={{color:'rgba(0, 0, 0, 0.87)', fontSize:'.875rem', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<PersonOutlineOutlinedIcon sx={{fontSize:'18px',color:'rgba(0, 0, 0, 0.87)'}}/>}  className='tabs_style '  iconPosition="start"  />
                      <StyledTab label="Send A Payment" {...a11yProps(2)}  style={{color:'rgba(0, 0, 0, 0.87)', fontSize:'.875rem', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<CheckCircleOutlinedIcon sx={{fontSize:'18px',color:'rgba(0, 0, 0, 0.87)'}}/>} className='tabs_style '  iconPosition="start"  />
                      <StyledTab label="Send Multiple Payments" {...a11yProps(3)} style={{color:'rgba(0, 0, 0, 0.87)', fontSize:'.875rem', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<FormatListBulletedOutlinedIcon sx={{fontSize:'18px',color:'rgba(0, 0, 0, 0.87)'}} />} className='tabs_style '  iconPosition="start"  />
                      <StyledTab label="Send Group Payments" {...a11yProps(4)} style={{color:'rgba(0, 0, 0, 0.87)', fontSize:'.875rem', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<PeopleAltOutlinedIcon sx={{fontSize:'18px',color:'rgba(0, 0, 0, 0.87)'}}/>} className='tabs_style '  iconPosition="start"  />
                      </StyledTabs>
                    </Box>
                    <CustomTabPanel value={value} index={0} sx={{ p: '0' }} className="panelInnerSide">
                      
                           {/* table section starts */}
                           <AllPaymentList data={transactionData} pageCount={pageCount}  page={page} handleChange={handleChange} />

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%',border:'1px solid #d4d4d4' }} >
                        <Paper sx={{ width: '100%',  borderRadius: 0 }} >
                          <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                            <Box sx={{ display: 'flex', }}>

                              <Typography
                                sx={{ px: '10px' }}
                                variant="p"
                                id="tableTitle"
                                component="div"
                                className='table-head-p'
                              >
                                <i class="fa-regular fa-file"></i> &nbsp; Export to Excel CSV
                              </Typography>
                              <Typography
                                sx={{ px: "10px" }}
                                variant="p"
                                id="tableTitle"
                                component="div"
                                className='table-head-p no-line'
                              >
                                <TextField placeholder='Search' className='searchHeadTable' />
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                              <Typography
                                sx={{ px: "20px" }}
                                variant="p"
                                id="tableTitle"
                                component="div"
                                className='table-head-p'
                              >
                                <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                              </Typography>


                              <BootstrapTooltip title="Clear">
                                <Typography
                                  sx={{ pl: "20px", borderRight: 'none' }}
                                  variant="p"
                                  id="tableTitle"
                                  component="div"
                                  className='table-head-p no-line'
                                >
                                  <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                </Typography></BootstrapTooltip>
                            </Box>
                          </Toolbar>

                          <TableContainer className='' sx={{ padding: '10px  30px' }}>
                            <Box className='table_head_btns users_marketplace'>
                              <Button className='table_inner_btn add_user' onClick={handleUserOpen}><i className="fa fa-add" aria-hidden="true"></i>&nbsp; Add User</Button>

                              {/* user modal Start */}
                              <BootstrapDialog
                                className='recurring_box'
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                                sx={{}}
                                fullWidth
                                maxWidth="sm"
                              >
                                <DialogTitle className=''  >
                                  <img src={cbLogo} className="modal_icon" />
                                  <Typography className='modalhead'>Create marketplace user</Typography>
                                </DialogTitle>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <DialogContent >
                                  <Box sx={{ mb: '1rem' }}>
                                    <Typography>Enter a name for the user and an id. The id can be an email address,
                                      but not necessarily. It can also be a unique identifier that matches the user
                                      in your system.</Typography>
                                  </Box>
                                  <Box sx={{ my: '1rem' }}>
                                    <InputLabel >
                                      Name
                                    </InputLabel>
                                    <FormControl variant="outlined" fullWidth className='user_modal_input' sx={{ mt: 1 }}>
                                      <OutlinedInput placeholder='New user name' value={userName} onChange={onChangeUser} />
                                    </FormControl>
                                  </Box>
                                  <Box sx={{ mb: '1rem' }}>
                                    <InputLabel >
                                      User Id
                                    </InputLabel>
                                    <FormControl variant="outlined" fullWidth className='user_modal_input' sx={{ mt: 1 }}>
                                      <OutlinedInput placeholder="Use user's email as unique id (recommended)" value={userEmail} onChange={onChangeUserEmail} />
                                    </FormControl>
                                  </Box>
                                  <Box height={30}>

                                  </Box>
                                </DialogContent>
                                <DialogActions className='user_bottom' sx={{padding:'16px'}} >
                                  <Button autoFocus onClick={handleClose} className='recurring_modal_cancel' >
                                    Cancel
                                  </Button>
                                  <Button autoFocus className='recurring_modal_btn' onClick={createNewUser} >
                                    Create User
                                  </Button>
                                </DialogActions>
                              </BootstrapDialog>

                              {/* user modal end */}

                              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Delete</Button>
                              <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
                            </Box>
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              className='table-head-color'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox

                                      className='checkbox_table'
                                    />
                                  </TableCell>

                                  <TableCell
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                  >
                                    User ID
                                  </TableCell>
                                  <TableCell
                                  >
                                    Publishable Key
                                  </TableCell>
                                  <TableCell
                                  >
                                    Secret Key
                                  </TableCell>
                                  <TableCell
                                  >
                                    Created
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {userData?.map((v, i) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        className='checkbox_table'
                                      />
                                    </TableCell>
                                    <TableCell><i className='fa fa-edit'></i>&nbsp;{v.name}</TableCell>
                                    <TableCell >{v.user_id}</TableCell>
                                    <TableCell><i className='fa fa-refresh primary' ></i> {v.publishable_key}</TableCell>
                                    <TableCell> {v.secret_key}</TableCell>
                                    <TableCell> {moment(v.date).format('l')}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Pagination count={pageUserCount} page={pageUser} onChange={handleChangeUser} className='pagination_dashboard' />
                        </Paper>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%',border:'1px solid #d4d4d4' }} >
                        <Paper sx={{ width: '100%',  borderRadius: 0 }} >
                          <Toolbar className='upperheader_forever payment_form_area'>
                            <Grid container spacing={2}>
                              <Grid item lg={4} xl={4}>
                                <Box className='payment_form payment_header'>
                                  <Box>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-user "></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth >
                                        <OutlinedInput placeholder="Payee's Name*" />
                                      </FormControl>
                                    </Box>
                                  </Box>


                                </Box>
                              </Grid>
                              <Grid item lg={8} xl={8}>
                                <Box sx={{ display: 'flex', padding: '25px 0', justifyContent: 'end', alignItems: 'center' }}>
                                  <Typography>Type in the name or the user id of the payer and select it from the list</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Toolbar>

                          <Box className='payment_form_area'>
                            <Grid container spacing={2} >
                              <Grid item xs={12} lg={4} xl={4} >
                                <Box className='payment_form'>
                                  <Box>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-user "></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth >
                                        <OutlinedInput placeholder="Payee's Name*" />
                                      </FormControl>
                                    </Box>

                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-envelope"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder="Payee's email, mobile phone number or mailing address*" />
                                      </FormControl>
                                    </Box>

                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-solid fa-dollar-sign"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder='Amount*' />
                                      </FormControl>
                                    </Box>

                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-solid fa-t"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder='Description' />
                                      </FormControl>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} lg={8} xl={8} >
                                <Box className='credit_form img_checkbook new_market' >
                                  <Box className="noneed" >
                                    <Box className="overlayBGimags">
                                      <img src={cbLogo} />
                                    </Box>
                                    <Box className="checkbox-box">
                                      <h3>JPMORGAN CHASE BANK, NA</h3>
                                      <Box className="logoText">
                                        <Box className="logoareandText">
                                          <Box className="leftmainLogo">
                                            <img src={cbLogo} />
                                          </Box>
                                          <Box className="logoRightText">
                                            <Box className="chekbokName"> Inc.</Box>
                                            <Typography variant='p'>1500 Fashion Island Boulevard Suite 103</Typography>
                                            <Typography variant='p'>San Mateo, CA, 94402</Typography>
                                          </Box>
                                        </Box>

                                        <Box className="logoaraRightTop">
                                          <Box className="cheque-number">30073</Box>
                                          <Box className="dateDesopoit"><Typography variant='span'>Date: </Typography><Typography variant='span'>Feb 5, 2024</Typography> </Box>
                                          <Box className="dateDesopoit"><Typography variant='span'>Amount: </Typography> $0.00</Box>
                                        </Box>
                                      </Box>

                                      <Box className="authorizedSign">
                                        <Box className="paytotxt">
                                          <Box className="labelPaye">Pay:</Box>
                                          <Box className="labelText">Zero and 00/100</Box>
                                          <Box className="labelPaye Bottom">To the Order of:</Box>
                                          <Box className="labelPaye Bottom">Memo:</Box>
                                        </Box>
                                        <Box className="siznixocn">
                                          {/* <img src={checkbook} /> */}
                                          <Typography variant='span' className="authoerizesSIgbn">Authorized Signature</Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className='box_area'></Box>
                          <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', borderRadius: 0 }} >
                              <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                                <Box sx={{ display: 'flex', }}>
                                  <Typography
                                    sx={{ paddingRight: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fas fa-calendar-alt"></i> &nbsp; All Transactions &nbsp; <i class="fa-solid fa-angle-down"></i>
                                  </Typography>
                                  <Typography
                                    sx={{ px: '10px' }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fa-regular fa-file"></i> &nbsp; Export to Excel CSV
                                  </Typography>
                                  <Typography
                                    sx={{ px: "10px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <TextField placeholder='Search' className='searchHeadTable' />
                                  </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                                  <Typography
                                    sx={{ px: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                  </Typography>
                                  <BootstrapTooltip title="Filter">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <FilterAltOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Settings">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p four'
                                    >
                                      <SettingsOutlined sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Clear">
                                    <Typography
                                      sx={{ pl: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                </Box>
                              </Toolbar>

                              <TableContainer className='' sx={{ padding: '10px  30px' }}>
                                <Box className='table_head_btns'>
                                  <Button className='table_inner_btn'><i className="fa fa-refresh" aria-hidden="true"></i>&nbsp; Resend Notification</Button>
                                  <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Void</Button>
                                  <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
                                </Box>
                                <Table
                                  sx={{ minWidth: 750 }}
                                  aria-labelledby="tableTitle"
                                  className='table-head-color'
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell padding="checkbox">
                                        <Checkbox

                                          className='checkbox_table'
                                        />
                                      </TableCell>

                                      <TableCell
                                      >
                                        Number
                                      </TableCell>
                                      <TableCell
                                      >
                                        Type
                                      </TableCell>
                                      <TableCell
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                      >
                                        Amount
                                      </TableCell>
                                      <TableCell
                                      >
                                        Status
                                      </TableCell>
                                      <TableCell
                                      >
                                        Method
                                      </TableCell>
                                      <TableCell
                                      >
                                        Address
                                      </TableCell>
                                      <TableCell
                                      >
                                        Payment Creation Date
                                      </TableCell>
                                      <TableCell
                                      >
                                        Description
                                      </TableCell>
                                      <TableCell
                                      >
                                        Attachment
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          color="primary"
                                          className='checkbox_table'
                                        />
                                      </TableCell>
                                      <TableCell><i className='fa fa-edit'></i>&nbsp;20043</TableCell>
                                      <TableCell >Check to</TableCell>
                                      <TableCell>Ja**h</TableCell>
                                      <TableCell>-$123</TableCell>
                                      <TableCell>Unpaid</TableCell>
                                      <TableCell >INSTANT</TableCell>
                                      <TableCell>tr**jd</TableCell>
                                      <TableCell>2/3/23 02:33 AM GMT+5</TableCell>
                                      <TableCell>Here you go</TableCell>
                                      <TableCell>Amount</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' />
                            </Paper>
                          </Box> </Paper>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%',border:'1px solid #d4d4d4' }} >
                        <Paper sx={{ width: '100%', borderRadius: 0 }} >
                          <Toolbar className='upperheader_forever payment_form_area'>
                            <Grid container spacing={2}>
                              <Grid item lg={4} xl={4}>
                                <Box className='payment_form payment_header'>
                                  <Box>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-user "></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth >
                                        <OutlinedInput placeholder="Payee's Name*" />
                                      </FormControl>
                                    </Box>
                                  </Box>


                                </Box>
                              </Grid>
                              <Grid item lg={8} xl={8}>
                                <Box sx={{ display: 'flex', padding: '25px 0', justifyContent: 'end', alignItems: 'center' }}>
                                  <Typography>Type in the name or the user id of the payer and select it from the list</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Toolbar>

                          <Box className=' payment_form_area ' >

                            <Grid container spacing={2} >
                              <Grid item xs={12} lg={5} xl={5} >
                                <Box className='payment_form'>
                                  <Box>
                                    <Typography variant='h6'>CSV file upload</Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Simply download and fill our template and upload the file and all the payments will go out in real-time.
                                    </Typography>
                                  </Box>
                                  <Box sx={{ my: 3 }}>
                                    <Typography variant='h6'>Select and upload your CSV file</Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Make sure you use the template CSV file you downloaded exactly as-is without modification and you read the "readme.txt" file that comes with it. </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} lg={7} xl={7} >
                                <Box className='payment_form' >
                                  <Box>
                                    <Typography variant='h6'>Template format</Typography>
                                  </Box>
                                  <Box>
                                    <Typography sx={{ fontSize: '14px', my: 1 }}>1 )<Link to="#"> Download the CSV template</Link></Typography>
                                    <Typography sx={{ fontSize: '14px' }}>2 ) Please do not remove the title row (row #1) from your CSV</Typography>
                                  </Box>

                                  <Box>
                                    <TableContainer component={Paper} sx={{ boxShadow: 0, mt: 1 }}>
                                      <Table aria-label="simple table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Payment No.</TableCell>
                                            <TableCell> Description</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>

                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              John Doe
                                            </TableCell>
                                            <TableCell>john@example.com</TableCell>
                                            <TableCell>12.30</TableCell>
                                            <TableCell>1001</TableCell>
                                            <TableCell> Food</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              John Doe
                                            </TableCell>
                                            <TableCell>john@example.com</TableCell>
                                            <TableCell>12.30</TableCell>
                                            <TableCell>1001</TableCell>
                                            <TableCell> Food</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              John Doe
                                            </TableCell>
                                            <TableCell>john@example.com</TableCell>
                                            <TableCell>12.30</TableCell>
                                            <TableCell>1001</TableCell>
                                            <TableCell> Food</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className='box_area'></Box>
                          <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', borderRadius: 0 }} >
                              <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                                <Box sx={{ display: 'flex', }}>
                                  <Typography
                                    sx={{ paddingRight: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fas fa-calendar-alt"></i> &nbsp; All Transactions &nbsp; <i class="fa-solid fa-angle-down"></i>
                                  </Typography>
                                  <Typography
                                    sx={{ px: '10px' }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fa-regular fa-file"></i> &nbsp; Export to Excel CSV
                                  </Typography>
                                  <Typography
                                    sx={{ px: "10px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <TextField placeholder='Search' className='searchHeadTable' />
                                  </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                                  <Typography
                                    sx={{ px: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                  </Typography>
                                  <BootstrapTooltip title="Filter">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <FilterAltOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Settings">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p four'
                                    >
                                      <SettingsOutlined sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Clear">
                                    <Typography
                                      sx={{ pl: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                </Box>
                              </Toolbar>

                              <TableContainer className='' sx={{ padding: '10px  30px' }}>
                                <Box className='table_head_btns'>
                                  <Button className='table_inner_btn'><i className="fa fa-refresh" aria-hidden="true"></i>&nbsp; Resend Notification</Button>
                                  <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Void</Button>
                                  <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
                                </Box>
                                <Table
                                  sx={{ minWidth: 750 }}
                                  aria-labelledby="tableTitle"
                                  className='table-head-color'
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell padding="checkbox">
                                        <Checkbox

                                          className='checkbox_table'
                                        />
                                      </TableCell>

                                      <TableCell
                                      >
                                        Number
                                      </TableCell>
                                      <TableCell
                                      >
                                        Type
                                      </TableCell>
                                      <TableCell
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                      >
                                        Amount
                                      </TableCell>
                                      <TableCell
                                      >
                                        Status
                                      </TableCell>
                                      <TableCell
                                      >
                                        Method
                                      </TableCell>
                                      <TableCell
                                      >
                                        Address
                                      </TableCell>
                                      <TableCell
                                      >
                                        Payment Creation Date
                                      </TableCell>
                                      <TableCell
                                      >
                                        Description
                                      </TableCell>
                                      <TableCell
                                      >
                                        Attachment
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          color="primary"
                                          className='checkbox_table'
                                        />
                                      </TableCell>
                                      <TableCell><i className='fa fa-edit'></i>&nbsp;20043</TableCell>
                                      <TableCell >Check to</TableCell>
                                      <TableCell>Ja**h</TableCell>
                                      <TableCell>-$123</TableCell>
                                      <TableCell>Unpaid</TableCell>
                                      <TableCell >INSTANT</TableCell>
                                      <TableCell>tr**jd</TableCell>
                                      <TableCell>2/3/23 02:33 AM GMT+5</TableCell>
                                      <TableCell>Here you go</TableCell>
                                      <TableCell>Amount</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' />
                            </Paper>
                          </Box> </Paper>
                      </Box>
                    </CustomTabPanel>
                    {/* Multi Party Payment section */}
                    <CustomTabPanel value={value} index={4} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%',border:'1px solid #d4d4d4' }} >
                        <Paper sx={{ width: '100%',  borderRadius: 0 }} >
                          <Toolbar className='upperheader_forever payment_form_area'>
                            <Grid container spacing={2}>
                              <Grid item lg={4} xl={4}>
                                <Box className='payment_form payment_header'>
                                  <Box>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-user "></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth >
                                        <OutlinedInput placeholder="Payee's Name*" />
                                      </FormControl>
                                    </Box>
                                  </Box>


                                </Box>
                              </Grid>
                              <Grid item lg={8} xl={8}>
                                <Box sx={{ display: 'flex', padding: '25px 0', justifyContent: 'end', alignItems: 'center' }}>
                                  <Typography>Type in the name or the user id of the payer and select it from the list</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Toolbar>

                          <Box className='payment_form_area'>
                            <Grid container spacing={2} >
                              <Grid item xs={12} lg={4} xl={4} >
                                <Box className='payment_form'>
                                  <Box>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-user "></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth >
                                        <OutlinedInput placeholder="Payee's Name*" />
                                      </FormControl>
                                    </Box>

                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-regular fa-envelope"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder="Payee's email, mobile phone number or mailing address*" />
                                      </FormControl>
                                    </Box>

                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-solid fa-dollar-sign"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder='Amount*' />
                                      </FormControl>
                                    </Box>

                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-solid fa-t"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder='Description' />
                                      </FormControl>
                                    </Box>
                                    <Typography variant="span" className='recipient_style'><i className='fa fa-add'></i> &ensp;Add another recipient</Typography>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-solid fa-dollar-sign"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder='Amount*' />
                                      </FormControl>
                                    </Box>
                                    <Box className="icon_input">
                                      <Typography variant="div" className='icon_style'>
                                        <i className="fa-solid fa-t"></i>
                                      </Typography>
                                      <FormControl variant="outlined" className='payment_input' fullWidth>
                                        <OutlinedInput placeholder='Description' />
                                      </FormControl>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} lg={8} xl={8} >
                                <Box className='credit_form img_checkbook new_market' >
                                  <Box className="noneed" >
                                    <Box className="overlayBGimags">
                                      <img src={cbLogo} />
                                    </Box>
                                    <Box className="checkbox-box">
                                      <h3>JPMORGAN CHASE BANK, NA</h3>
                                      <Box className="logoText">
                                        <Box className="logoareandText">
                                          <Box className="leftmainLogo">
                                            <img src={cbLogo} />
                                          </Box>
                                          <Box className="logoRightText">
                                            <Box className="chekbokName"> Inc.</Box>
                                            <Typography variant='p'>1500 Fashion Island Boulevard Suite 103</Typography>
                                            <Typography variant='p'>San Mateo, CA, 94402</Typography>
                                          </Box>
                                        </Box>

                                        <Box className="logoaraRightTop">
                                          <Box className="cheque-number">30073</Box>
                                          <Box className="dateDesopoit"><Typography variant='span'>Date: </Typography><Typography variant='span'>Feb 5, 2024</Typography> </Box>
                                          <Box className="dateDesopoit"><Typography variant='span'>Amount: </Typography> $0.00</Box>
                                        </Box>
                                      </Box>

                                      <Box className="authorizedSign">
                                        <Box className="paytotxt">
                                          <Box className="labelPaye">Pay:</Box>
                                          <Box className="labelText">Zero and 00/100</Box>
                                          <Box className="labelPaye Bottom">To the Order of:</Box>
                                          <Box className="labelPaye Bottom">Memo:</Box>
                                        </Box>
                                        <Box className="siznixocn">
                                          {/* <img src={checkbook} /> */}
                                          <Typography variant='span' className="authoerizesSIgbn">Authorized Signature</Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className='box_area'></Box>
                          
                          <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', borderRadius: 0 }} >
                              <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                                <Box sx={{ display: 'flex', }}>
                                  <Typography
                                    sx={{ paddingRight: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fas fa-calendar-alt"></i> &nbsp; All Transactions &nbsp; <i class="fa-solid fa-angle-down"></i>
                                  </Typography>
                                  <Typography
                                    sx={{ px: '10px' }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fa-regular fa-file"></i> &nbsp; Export to Excel CSV
                                  </Typography>
                                  <Typography
                                    sx={{ px: "10px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <TextField placeholder='Search' className='searchHeadTable' />
                                  </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                                  <Typography
                                    sx={{ px: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                  </Typography>
                                  <BootstrapTooltip title="Filter">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <FilterAltOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Settings">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p four'
                                    >
                                      <SettingsOutlined sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Clear">
                                    <Typography
                                      sx={{ pl: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                </Box>
                              </Toolbar>

                              <TableContainer className='' sx={{ padding: '10px  30px' }}>
                                <Box className='table_head_btns'>
                                  <Button className='table_inner_btn'><i className="fa fa-refresh" aria-hidden="true"></i>&nbsp; Resend Notification</Button>
                                  <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Void</Button>
                                  <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
                                </Box>
                                <Table
                                  sx={{ minWidth: 750 }}
                                  aria-labelledby="tableTitle"
                                  className='table-head-color'
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell padding="checkbox">
                                        <Checkbox

                                          className='checkbox_table'
                                        />
                                      </TableCell>

                                      <TableCell
                                      >
                                        Number
                                      </TableCell>
                                      <TableCell
                                      >
                                        Type
                                      </TableCell>
                                      <TableCell
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                      >
                                        Amount
                                      </TableCell>
                                      <TableCell
                                      >
                                        Status
                                      </TableCell>
                                      <TableCell
                                      >
                                        Method
                                      </TableCell>
                                      <TableCell
                                      >
                                        Address
                                      </TableCell>
                                      <TableCell
                                      >
                                        Payment Creation Date
                                      </TableCell>
                                      <TableCell
                                      >
                                        Description
                                      </TableCell>
                                      <TableCell
                                      >
                                        Attachment
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          color="primary"
                                          className='checkbox_table'
                                        />
                                      </TableCell>
                                      <TableCell><i className='fa fa-edit'></i>&nbsp;20043</TableCell>
                                      <TableCell >Check to</TableCell>
                                      <TableCell>Ja**h</TableCell>
                                      <TableCell>-$123</TableCell>
                                      <TableCell>Unpaid</TableCell>
                                      <TableCell >INSTANT</TableCell>
                                      <TableCell>tr**jd</TableCell>
                                      <TableCell>2/3/23 02:33 AM GMT+5</TableCell>
                                      <TableCell>Here you go</TableCell>
                                      <TableCell>Amount</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' />
                            </Paper>
                          </Box> 
                          
                          </Paper>
                      </Box>
                    </CustomTabPanel>
                  </Box>


                </Box>
              </Box>
            </Box>
            <Box className='inner-section' display={display==false ? "none":"block"} >
                           <Loader display={display} />
                      </Box>
          </Box>
        </Box>
      </Box>

      <ToastContainer />


    </>
  )
}

export default Marketplace


