import { Box, Button, Container, Grid, Typography, FormControl, OutlinedInput } from '@mui/material'
import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import '../assets/css/responsive.css';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import LogoIcon from '../assets/images/dashboard_icon.svg'
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import mainLogo from '../assets/images/main-logo.jpg';
import checkbook from '../assets/images/checkbook.png';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ModalLogo from '../assets/images/viable-logo.jpeg'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0e2e7d',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ApprovePayments = () => {
    const [bank, setBank] = React.useState("");
    const handleBank = (e) => {
        setBank(e.target.value);
    };


    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: '14px',
            textAlign: 'center',
        },
    }));
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Navbar />
            <Box height={30} />
            <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
                    <Box>
                        <Box className='upper-bluearea'>
                            <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
                        </Box>
                        <Box className='inner-section'>
                            <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Approve Payments</Typography>

                            {/* table section starts */}

                            <Box className='forever_table ' >
                                <Box className='dashboard_table'>
                                    <Box sx={{ width: '100%' }}>
                                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                                            <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                                                <Box sx={{ display: 'flex', }}>

                                                    <Typography
                                                        sx={{ paddingRight: "20px" }}
                                                        variant="p"
                                                        id="tableTitle"
                                                        component="div"
                                                        className='table-head-p'
                                                    >
                                                        <i class="fas fa-calendar-alt"></i> &nbsp; All Transactions &nbsp; <i class="fa-solid fa-angle-down"></i>
                                                    </Typography>
                                                    <Typography
                                                        sx={{ px: "10px" }}
                                                        variant="p"
                                                        id="tableTitle"
                                                        component="div"
                                                        className='table-head-p no-line'
                                                    >
                                                        <TextField placeholder='Search' className='searchHeadTable' />
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                                                    <Typography
                                                        sx={{ px: "20px" }}
                                                        variant="p"
                                                        id="tableTitle"
                                                        component="div"
                                                        className='table-head-p'
                                                    >
                                                        <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                                    </Typography>


                                                    <BootstrapTooltip title="Clear">
                                                        <Typography
                                                            sx={{ pl: "20px", borderRight: 'none' }}
                                                            variant="p"
                                                            id="tableTitle"
                                                            component="div"
                                                            className='table-head-p no-line'
                                                        >
                                                            <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                                        </Typography></BootstrapTooltip>
                                                </Box>
                                            </Toolbar>

                                            <TableContainer className='' sx={{ padding: '10px  30px' }}>
                                                <Box className='table_head_btns users_marketplace'>
                                                    <Button className='table_inner_btn add_user'><CheckBoxOutlinedIcon sx={{ fontSize: '18px' }} />&nbsp; Approve payments</Button>
                                                    <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Remove</Button>
                                                    <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
                                                </Box>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    className='table-head-color'
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox

                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>

                                                            <TableCell
                                                            >
                                                                Number
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Recipient
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Name
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Amount
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Status
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Payment Creation Date
                                                            </TableCell>

                                                            <TableCell
                                                            >
                                                                Description
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Created By
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Attachment
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>none</TableCell>
                                                            <TableCell >re*****@*****om</TableCell>
                                                            <TableCell>James Backus Jr</TableCell>
                                                            <TableCell>	$7,000.00</TableCell>
                                                            <TableCell>	appoved</TableCell>
                                                            <TableCell >	2/14/24 01:02 AM GMT+5</TableCell>
                                                            <TableCell>	PA*****EL	</TableCell>
                                                            <TableCell>-</TableCell>
                                                            <TableCell>-</TableCell>

                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' />
                                        </Paper>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <ToastContainer />
        </>
    )
}
export default ApprovePayments