import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: '',
    password: '',
    emailError: false,
    passwordError: false,
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    //   state.emailError = false; // Reset error when email changes
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    //   state.passwordError = false; // Reset error when password changes
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    validateEmail: (state) => {
      // Simple email validation
      state.emailError = !state.email.includes('@');
    },
    validatePassword: (state) => {
      // Simple password validation
      state.passwordError = state.password.length < 8;
    },
  },
});

export const {
  setEmail,
  setPassword,
  setEmailError,
  setPasswordError,
  validateEmail,
  validatePassword,
} = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
