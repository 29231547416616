import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loginus from './Components/Loginus';
import Dashboard from './Components/Dashboard';
import Marketplace from './Components/Marketplace';
import SendPayment from './Components/SendPayment';
import { Provider } from 'react-redux';
import store from './Components/store';
import AccountCard from './Components/AccountCards';
import SendManyPayments from './Components/SendManyPayments';
import Reports from './Components/Reports';
import SendInvoice from './Components/SendInvoice';
import ApprovePayments from './Components/ApprovePayments';
import SendMultipleInvoices from './Components/SendMultipleInvoices';


function App() {
  
  return (
<>
<Provider store={store}>
    <BrowserRouter>   
        <Routes>
            <Route path="/" element={<Loginus/>} exact={true}/>
            <Route path="/dashboard" element={<Dashboard/>} exact={true}/>
            <Route path="/marketplace" element={<Marketplace/>} exact={true}/>
            <Route path="/sendpayment" element={<SendPayment/>} exact={true}/>
            <Route path="/account-card" element={<AccountCard/>} exact={true}/>
            <Route path="/send-multiple-payments" element={<SendManyPayments/>} exact={true}/>
            <Route path="/reports" element={<Reports/>} exact={true}/>
            <Route path="/sendinvoice" element={<SendInvoice/>} exact={true}/>
            <Route path="/approvepayments" element={<ApprovePayments/>} exact={true}/>
            <Route path="/send-multiple-invoices" element={<SendMultipleInvoices/>} exact={true}/>
           
        </Routes> 
    </BrowserRouter>
    </Provider>
</>
  );
}

export default App;
