import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Alert,
} from "@mui/material";
import React,{ useState, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Padding, SettingsOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from 'axios';
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import LogoIcon from "../assets/images/dashboard_icon.svg";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import cbLogo from "../assets/images/pb-logo-removebg-preview.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DirectPaymentModel(props) {
   
    const [country,setCountry] =  useState("US");
    const [hideShowCont,sethideShowCont]  = useState(false);
    const [hideShowACH,sethideShowACH]  = useState(false);
    const [routing,setRouting] = useState("");
    const [showUsMsg,setShowUsMsg] = useState(false);
    const [routingError,setRoutingError] = useState(false);
    const [showHideAccountDetails,setShowHideAccountDetails] = useState(false);
    const [accountNo,setAccountNo] = useState("");
    const [confirmAccountNo,setConfirmAccountNo] = useState("");
    const [accountType,setAccountType] = useState("CHECKING");
    const [transition,setTransition] = useState("");
    const [institutionNo,setInstitutionNo] = useState("");

    const[debitCard,setDebitCard] = useState("");
    const[debitDate,setDebitDate] = useState(0);
    const[debitYear,setDebitYear] = useState(0);

    
    const [accountNoError,setAccountNoError] = useState(false);
    const [confirmAccountNoError,setConfirmAccountNoError] = useState(false);
    const [sameAccountError,setSameAccountError] = useState(false);

    const [institutionNoError,setInstitutionNoError] =useState(false);
    const [transitionError,setTransitionError] =useState(false);

    const [debitCardError,setDebitCardError] = useState(false);
    const [debitDateError,setDebitDateError] = useState(false);
    const [debitYearError,setDebitYearError] = useState(false);

    const handleCountry = (e) =>{
        setCountry(e.target.value);
        if(e.target.value=='CA'){
            sethideShowCont(true);
        }else{
            sethideShowCont(false);
        }
    }
   
    const handleACHorDebit = (data) =>{
        if(data=='rtp'){
            sethideShowACH(true)
        }
        if(data=='ach'){
            sethideShowACH(false)
        }
    }
       
   const handelAccountNo= (e) =>{
    if(e.target.value >=0 && e.target.value.length <=17){
      setAccountNo(e.target.value);
        setAccountNoError(false);
    }
        
   }

   const[showAccount,setShowAccountType] = useState(false);

   const handelConfirmAccountNo= (e) =>{
      if(e.target.value >=0 && e.target.value.length <=17){ 
        setConfirmAccountNo(e.target.value);
      if(e.target.value.length >= 4 && e.target.value.length <=17){
      // console.log("if",e.target.value.length);
         if(accountNo ==  e.target.value){
          setShowAccountType(true);
         }else{
          setShowAccountType(false);
         }
      }else{
       
        setShowAccountType(false);
      } 
      setConfirmAccountNoError(false);
    }
      
   }

  const handelChangeAccountType = (e) =>{
       setAccountType(e.target.value);
  }


    const handelRoutingNo = (e) =>{
      if(e.target.value >=0 && e.target.value.length<=9){
        setShowUsMsg(true);
        setRouting(e.target.value);
         
        if(e.target.value.length==9){
          setShowHideAccountDetails(true);
        }else{
          setShowHideAccountDetails(false);
        }
        setRoutingError(false);
      }
    }

   

    const closeDirectPayment = () =>{

          props.close(false);
          restAll();

    }

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
  const [validEmail,setValidEmail] = useState(true);
    useEffect(()=>{
      setValidEmail(validateEmail(props.email))
    },[props.email])

  const handleTransation = (e)=>{
    if(e.target.value >=0 && e.target.value.length<=5){  
    setTransition(e.target.value);
    setTransitionError(false);
    }
  }

  const handleinstitutionNo = (e)=>{
   if(e.target.value >=0 && e.target.value.length<=3){
    setInstitutionNo(e.target.value);
    if(e.target.value.length==3){  
      setShowHideAccountDetails(true);
    }else{
      setShowHideAccountDetails(false);
    }
    setInstitutionNoError(false);
  }
  }
    
  const handelDebitCard = (e) =>{
    if(e.target.value >=0 && e.target.value.length<=16){

       setDebitCard(e.target.value);
       setDebitCardError(false);
    }
  }

  const handelDebitDate = (e) =>{
       setDebitDate(e.target.value);
       setDebitDateError(false)
  }

  const handelDebitYear = (e) =>{
    setDebitYear(e.target.value);
    setDebitYearError(false)
}

 

const handleConfirmSubmit = (e) =>{
 if(!hideShowACH){ 
   if(!hideShowCont){ 
        if(handelValidationUs()){
          let data={
            routing_number:routing,
            account_type:accountType,
            account_number:accountNo,
            account_id :props.account_id,
            amount:props.amount,
            memo:props.description,
            description:props.description,
            name:props.name,
            recipient:props.email,
          }  
            let dataMain = JSON.stringify(data);

            let config = {
              method: 'post',
              url: 'https://sandbox.checkbook.io/v3/check/digital',
              headers: {
                'accept': 'application/json',
                'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
                'Content-Type': 'application/json'
              },
              data: dataMain
            };
              axios.request(config)
                .then((response) => {
                  let res = response.data
                  if (res.status) {
                    toast.success("Added Sucessfully.");
                    props.processData();
                    props.close(false);
                    restAll();
                  }
                })
                .catch((error) => {
              //    console.log(error);
                  toast.error(error?.response?.data?.error);  
                });
        }   
    }else{
      if(handelValidationCA()){
        let data={
          routing_number:"0"+transition+institutionNo,
          account_type:accountType,
          account_number:accountNo,
          account_id :props.account_id,
          amount:props.amount,
          memo:props.description,
          description:props.description,
          name:props.name,
          recipient:props.email,
          }
          let dataMain = JSON.stringify(data);

          let config = {
            method: 'post',
            url: 'https://sandbox.checkbook.io/v3/check/digital',
            headers: {
              'accept': 'application/json',
              'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
              'Content-Type': 'application/json'
            },
            data: dataMain
          };
            axios.request(config)
              .then((response) => {
                let res = response.data
                if (res.status) {
                  toast.success("Added Sucessfully.");
                  props.processData();
                  props.close(false);
                  restAll();
                }
              })
              .catch((error) => {
            //    console.log(error);
                toast.error(error?.response?.data?.error);  
              });
      } 

    }
 }else{
    if(handelValaditionDebit()){
      let data={
        card_number:debitCard,
        expiration_date:debitYear+"-"+debitDate,
        account_id :props.account_id,
        amount:props.amount,
        memo:props.description,
        description:props.description,
        name:props.name,
        recipient:props.email,
      }

      let dataMain = JSON.stringify(data);
      let config = {
        method: 'post',
        url: 'https://sandbox.checkbook.io/v3/check/digital',
        headers: {
          'accept': 'application/json',
          'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
          'Content-Type': 'application/json'
        },
        data: dataMain
      };
        axios.request(config)
          .then((response) => {
            let res = response.data
            if (res.status) {
              toast.success("Added Sucessfully.");
              props.processData();
              props.close(false);
              restAll();
            }
          })
          .catch((error) => {
        //    console.log(error);
            toast.error(error?.response?.data?.error);  
          });
      
    }
 }
   
}

const handelValidationUs = ()=>{  
  let formData = true;
  
    if(routing.length !=9){
      formData =false  
      setRoutingError(true);
    }
   else if(accountNo==""){
      formData =false  
      setAccountNoError(true);
    }
   else if(confirmAccountNo==""){
      formData =false  
      setConfirmAccountNoError(true);
    }
   
   else if(accountNo!=confirmAccountNo){ 
      formData =false  
      setSameAccountError(true);
    }

    
console.log("validationUS",formData)
    return formData;
}



const handelValidationCA = ()=>{  
  let formData = true;
  
    if(transition==""){
      formData =false  
      setTransitionError(true);
    }
   else if(institutionNo==""){
      formData =false  
      setInstitutionNoError(true);
    }
   else if(accountNo==""){
      formData =false  
      setAccountNoError(true);
    }
   else if(confirmAccountNo==""){
      formData =false  
      setConfirmAccountNoError(true);
    }
   
    else if(accountNo!=confirmAccountNo){
      formData =false  
      setSameAccountError(true);
     
    }
    
console.log("validationCA",formData)
    return formData;
}


const handelValaditionDebit = ()=>{  
  let formData = true;
  
    if(debitCard==""){
      formData =false  
      setDebitCardError(true);
    }
    else if(debitDate==0){
      formData =false  
      setDebitDateError(true);
    }
   else if(debitYear==0){
      formData =false  
      setDebitYearError(true);
    }
   
    
console.log("validationDebit",formData)
    return formData;
}

useEffect(()=>{
  
  if(!confirmAccountNoError && !accountNoError){   
    setSameAccountError(false);
  }
},[confirmAccountNo,accountNo])

const onNumerInputKeyDown = (event) => {
  if(event.key === "e") event.preventDefault();
  if(event.key === "-") event.preventDefault();
}

useEffect(()=>{
  
  handleACHorDebit(props.type)
},[props])


const restAll = () =>{
      setRouting("");
      setShowUsMsg(false);
      setRoutingError(false);
      setShowHideAccountDetails(false);
      setAccountNo("");
      setConfirmAccountNo("");
      setAccountType("CHECKING");
      setTransition("");
      setInstitutionNo("");
      setDebitCard("");
      setDebitDate(0);
      setDebitYear(0);
    setAccountNoError(false);
    setConfirmAccountNoError(false);
    setSameAccountError(false);
    setInstitutionNoError(false);
    setTransitionError(false);
    setDebitCardError(false);
    setDebitDateError(false);
    setDebitYearError(false);
} 




  return (
    <>
      <Box sx={{ background: "#fff" }}>
        <Box>
          <Dialog
            className="recurring_box"
            onClose={closeDirectPayment}
            aria-labelledby="customized-dialog-title"
            open={props.openCLose}
            sx={{}}
            fullWidth
            scroll='body'
            maxWidth="sm"
          >
            <DialogTitle className="" sx={{px:'16px'}} >
              <img src={cbLogo} className="modal_icon" />
              <Typography className="modalhead">
                (Optional) Direct Deposit
              </Typography>
              {(!hideShowACH)?
               <><FormControl sx={{ my: 0, minWidth: 120 }} size="small">
                <Select 
                value={country}
                onChange={handleCountry}
                >
                  <MenuItem value="US">United States</MenuItem>
                  <MenuItem value="CA">Canada</MenuItem>
                  
                </Select>
              </FormControl></>
              :""
                }
               { (validEmail)?
              <Box>
                <Typography sx={{ textAlign: "justify", my: 1}}  className="color-label-black">
                  Enter your recipient's banking information only if you know
                  it. This method is optional and you can send a Digital Payment
                  instead if you only know your recipient's email address.
                </Typography>
              </Box>
               :"" }
            </DialogTitle>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={closeDirectPayment}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid container>
                {(validEmail)?(!hideShowACH)?(!hideShowCont)? <>
                <Grid item md={12} >
                  <Box sx={{ my: 2 }}>
                    <InputLabel className="color-label-black">Routing number</InputLabel>
                    <Box className="input_a style_height">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput placeholder="Enter Routing number" type="text"  value={routing} onChange={handelRoutingNo} onKeyDown={onNumerInputKeyDown} />
                      </FormControl>
                     
                    </Box>
                    {/* { showUsMsg ?
                     
                     <>  <Box sx={{ my: '1rem' }} className="modal_yellowarea">
                     <Typography>
                         Questions ? chat<Link to="#"> online</Link>or <Link to="#">support@checkbook.io</Link>
                     </Typography>
                   </Box></>
                     :""} */}

                    { routingError ?
                     
                     <>  <Box sx={{ my: '1rem' }} className="modal_redarea">
                     <Typography>
                           Invalid Routing number.
                     </Typography>
                   </Box></>
                     :""}
                      
                  </Box>
                </Grid>
                </>
                  :
                  <>
                <Grid item md={6}>
                  <Box sx={{ mr: 1 }}>
                    <InputLabel className="color-label-black">Transit number</InputLabel>
                    <Box className="input_a style_height">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput placeholder="Enter your bank's transit number"  type="text" value={transition} onChange={handleTransation} onKeyDown={onNumerInputKeyDown} />
                      </FormControl>
                    </Box>
                    { transitionError ?
                     <>  <Box sx={{ my: '1rem' }} className="modal_redarea">
                     <Typography>
                           Please enter Transit number.
                     </Typography>
                   </Box></>
                     :""}
                  </Box>
                  
                

                    
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ ml: 1 }}>
                    <InputLabel className="color-label-black">
                      Institution number &emsp;{" "}
                      <i className="fa-solid fa-circle-question"></i>
                    </InputLabel>
                    <Box className="input_a style_height">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput placeholder="Institution number"  type="text"  value={institutionNo} onChange={handleinstitutionNo} onKeyDown={onNumerInputKeyDown}/>
                      </FormControl>
                    </Box>
                    { institutionNoError ?
                     <>  <Box sx={{ my: '1rem' }} className="modal_redarea">
                     <Typography>
                           Please enter Institution number.
                     </Typography>
                   </Box></>
                     :""}
                  </Box>
                  
                </Grid>

                </>
                   :
                   <>
                <Grid item md={12}>
                  <Box sx={{ my: 2 }}>
                    <Box className="input_a style_height">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput placeholder="Debit Card number" type="text" value={debitCard} onChange={handelDebitCard} onKeyDown={onNumerInputKeyDown}/>
                      </FormControl>
                    </Box>
                    { debitCardError ?
                     <>  <Box sx={{ my: '1rem' }} className="modal_redarea">
                     <Typography>
                           Please enter the Debit Card number.
                     </Typography>
                   </Box></>
                     :""}
                  </Box>
                </Grid>
                <Grid container sx={{mb:1}}>
                  <Grid item md={3}>
                    <Typography
                      sx={{
                        height: "42px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="color-label-black"
                    >
                      Expiration :
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth sx={{ mr: 1 }}>
                      <Select  value={debitDate} onChange={handelDebitDate} sx={{height:'42px' }}>
                        <MenuItem value="0">MM</MenuItem>
                        {[...Array(12)].map((x, i) =>
                                              
                        <MenuItem value={i+1} key={i+1}>{i+1}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    { debitDateError ?
                     <>  <Box sx={{ my: '1rem' }} className="modal_redarea">
                     <Typography>
                           Select expiration month.
                     </Typography>
                   </Box></>
                     :""}
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth sx={{ ml: 6 }}>
                      <Select  value={debitYear} onChange={handelDebitYear} sx={{height:'42px' }}>
                        <MenuItem value="0">YYYY</MenuItem>
                        {[...Array(5)].map((x, i) =>
                                              
                            <MenuItem value={i+2024} key={i}>{i+2024}</MenuItem>
                         )}
                      </Select>
                    </FormControl>
                    { debitYearError ?
                     <>  <Box sx={{ my: '1rem',ml: 6,width:'100%'  }} className="modal_redarea">
                     <Typography >
                     Select expiration year.
                     </Typography>
                   </Box></>
                     :""}
                  </Grid>
                </Grid>
                </>
                : <>  <Box sx={{ my: '1rem' }} className="modal_redarea">
                <Typography>
                The recipient address must be an email address if you want to send a Direct Deposit Payment. Please go back and enter an email address and try again.
                </Typography>
              </Box></> }
              </Grid>

              <Box height={10}></Box>
            </DialogContent>
               
            {
                     (!hideShowACH && showHideAccountDetails) ? 
                      <>
                       <Grid container  sx={{padding:'0 16px'}}>
                      <Grid item md={6}>
                        <Box sx={{ mr: 1 }}>
                          <InputLabel className="color-label-black">Account number</InputLabel>
                          <Box className="input_a style_height">
                            <FormControl variant="outlined" fullWidth>
                              <OutlinedInput placeholder="Account number" type="text" value={accountNo} onChange={handelAccountNo} onKeyDown={onNumerInputKeyDown} />
                            </FormControl>
                          </Box>
                          { accountNoError ?
                     <>  <Box sx={{ my: '1rem',ml:0,width:'100%'  }} className="modal_redarea">
                     <Typography >
                     Please enter Account number.
                     </Typography>
                   </Box></>
                     :""}
                        </Box>
                       
                      </Grid>
                      <Grid item md={6}>
                        <Box sx={{ ml: 1 }}>
                          <InputLabel className="color-label-black">
                             Confirm Account number
                           
                          </InputLabel>
                          <Box className="input_a style_height">
                            <FormControl variant="outlined" fullWidth>
                              <OutlinedInput placeholder="Confirm Account number" type="text" value={confirmAccountNo} onChange={handelConfirmAccountNo} onKeyDown={onNumerInputKeyDown} />
                            </FormControl>
                          </Box>
                          { confirmAccountNoError ?
                     <>  <Box sx={{ my: '1rem',ml:0,width:'100%'  }} className="modal_redarea">
                     <Typography >
                     Please enter Confirm Account number
                     </Typography>
                   </Box></>
                     :""}
                        </Box>

                       
                       </Grid>
                       
                      </Grid>
                      
<Grid container>
  <Grid md={12} sx={{mx:2}}>
  {( sameAccountError) ?
                     
                     <>  <Box sx={{ py:1 }} className="modal_redarea">
                     <Typography>
                          {(sameAccountError)?"Account number and Confirm Account number are not same ":""}
                      
                     </Typography>
                   </Box></>
                     :""}
  </Grid>
</Grid>

                      {showAccount ? 
                     <> 
                     <Box sx={{padding:'0 16px'}}>
                      <InputLabel className="color-label-black">Account Type</InputLabel>
                      <Box className="input_a style_height" >
                        <FormControl variant="outlined" fullWidth>
                            <Select value={accountType} onChange={handelChangeAccountType}  sx={{height:'42px' }}>
                                 <MenuItem value="CHECKING">Personal Checking</MenuItem>
                                 <MenuItem value="SAVINGS">Personal Savings</MenuItem>
                                 <MenuItem value="BUSINESS">Business Account</MenuItem>
                           
                          </Select>
                        </FormControl>
                       
                      </Box>
                      </Box>
                      </>
                      :
                      ""}
                      </>
                      : 
                      ""
                    }

                   


            <DialogActions className="user_bottom button-end">
             {/* {(!hideShowACH)? 
               <Button autoFocus className="recurring_modal_cancel" onClick={e=>{handleACHorDebit('rtp')}}>
                ACH
               </Button>
                :
                <Button autoFocus className="recurring_modal_cancel" onClick={e=>{handleACHorDebit('ach')}}>
                Debit
                </Button>
                } */}
              <Button sx={{display:'flex',justifyContent:'end'}} autoFocus className="recurring_modal_btn" onClick={handleConfirmSubmit}>
                Confirm & Send Payment
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
}

export default DirectPaymentModel;
