import { Box, Button, Container, Grid, Typography, FormControl, OutlinedInput } from '@mui/material'
import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import '../assets/css/responsive.css';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import LogoIcon from '../assets/images/dashboard_icon.svg'
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import templateCSV from '../files/jjs_csv_template.zip'
import currencyFormatter from 'currency-formatter';
import Loader from './Layouts/Loader.js'
import { IOSSwitch, BootstrapDialog, BootstrapTooltip } from './commonStyle/muiCustomStyle.js';
import AllPaymentList from './allPaymentList.js';

const SendMulitpleInvoices = () => {
  const [bank, setBank] = React.useState("");
  const [bankData, setBankData] = useState([])
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState(false);

  const handleBank = (e) => {
    setBank(e.target.value);
  };




  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const getbankData = async () => {
    let config = {
      method: 'GET',
      url: 'https://sandbox.checkbook.io/v3/account/bank',
      headers: {
        'accept': 'application/json',
        'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {
        setBankData(response?.data?.banks);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const addressFormatter = (address) => {
    if (address.hasOwnProperty('city')) {
      return address?.line_1 + (address?.line_2 ? ', ' + address?.line_1 + ', ' : '') + (address?.city ? ', ' + address?.city : '') + (address?.state ? ', ' + address?.state : '') + (address?.zip ? ', ' + address?.zip : '')
    } else {
      return address;
    }
  }
  const [perPageData, setPerPageData] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const getData = async () => {
    setDisplay(true);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://sandbox.checkbook.io/v3/check?page=${page}&per_page=${perPageData}`,
      headers: {
        'accept': 'application/json',
        'Authorization': ' e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data)
        setData(response.data?.checks);
        setPageCount(response.data?.pages)
        setDisplay(false);
      })
      .catch((error) => {
        setDisplay(false);
        console.log(error);
      });

  }
  useEffect(() => {
    getData();
    getbankData();
  }, []);
  useEffect(() => {
    getData();
  }, [page])

  return (
    <>
      <Navbar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
          <Box>
            <Box className='upper-bluearea'>
              <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
            </Box>
            <Box className='inner-section' display={display == false ? "block" : "none"} >
              <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Send Multiple Invoices</Typography>
              <Box className='payment_form_area'>
                <Grid container spacing={3} >
                  <Grid item xs={12} lg={5} md={5} xl={5} >
                    <Box className='payment_form many_payment '>
                      <Box>
                        <Box>
                          <Typography variant='h5'>CSV file upload</Typography>
                        </Box>
                        <Box>
                          <Typography component='p' variant='p' sx={{ fontSize: '16px', py: 1 }}>Simply download and fill our template and upload the file and all the invoices will go out in real-time.
                          </Typography>
                        </Box>
                      

                       

                        <Box sx={{ py: 5 }}>
                        <Typography variant='h5' component='h5' sx={{ my: 1 }}>Select and upload your CSV file</Typography>
                          <Typography variant='p' component='p'>Make sure you use the template CSV file you downloaded exactly as-is without modification and you
                           read the "readme.txt" file that comes with it.</Typography>
                        </Box>
                        <Box className="attach_file">
                          <FormControl variant="outlined" className='payment_input_choose' fullWidth sx={{ mr: 1 }}>
                            <Typography variant='span' className='attach_pdf' sx={{ width: '150px' }} ><i className="fa-regular fa-file"></i>&ensp; Attach CSV File &ensp;<i className="fa-solid fa-circle-question"></i></Typography>
                            <OutlinedInput type="file" className='input_file pdf' accept=".pdf" />
                          </FormControl>
                          <Button className="send_many_payment " sx={{ my: 0 }}>Send Invoices</Button>
                        </Box>

                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={7} lg={7} xl={7} >
                    <Box className='payment_form many_payment' >
                      <Box>
                        <Typography variant='h5'>Template format</Typography>
                      </Box>
                      <Box sx={{ my: 2 }}>
                        <Typography sx={{ fontSize: '16px', my: 2 }}>1 )<Link to={templateCSV} target="_blank" className='link-style'> Download </Link> the CSV template</Typography>
                        <Typography sx={{ fontSize: '16px', my: 2 }}>2 ) Please do not remove the title row (row #1) from your CSV</Typography>
                      </Box>

                      <Box>
                        <TableContainer component={Paper} sx={{ boxShadow: 0, my: 1 }}>
                          <Table aria-label="simple table" className='demo_table'>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Invoice No.</TableCell>
                                <TableCell> Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                              <TableRow>
                                <TableCell component="th" scope="row">
                                  John Doe
                                </TableCell>
                                <TableCell>john@example.com</TableCell>
                                <TableCell>12.30</TableCell>
                                <TableCell>1001</TableCell>
                                <TableCell> Food</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Mary Smith
                                </TableCell>
                                <TableCell>mary@example.com</TableCell>
                                <TableCell>12.30</TableCell>
                                <TableCell>1001</TableCell>
                                <TableCell> Food</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Landlord Inc.
                                </TableCell>
                                <TableCell>landlord@example.com</TableCell>
                                <TableCell>12.30</TableCell>
                                <TableCell>1001</TableCell>
                                <TableCell> Food</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>

                  </Grid>
                </Grid>
              </Box>

              {/* table section starts */}
              <Box className='forever_table ' >
                                <Box className='dashboard_table padding-section'>
                                    <Box sx={{ width: '100%' }}>
                                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                                        <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                                <Box sx={{ display: 'flex', }}>
                                  <Typography
                                    sx={{ paddingRight: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fas fa-calendar-alt"></i> &nbsp; All Transactions &nbsp; <i class="fa-solid fa-angle-down"></i>
                                  </Typography>
                                  <Typography
                                    sx={{ px: '10px' }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <i class="fa-regular fa-file"></i> &nbsp; Export to Excel CSV
                                  </Typography>
                                  <Typography
                                    sx={{ px: "10px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <TextField placeholder='Search' className='searchHeadTable' />
                                  </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                                  <Typography
                                    sx={{ px: "20px" }}
                                    variant="p"
                                    id="tableTitle"
                                    component="div"
                                    className='table-head-p'
                                  >
                                    <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                  </Typography>
                                  <BootstrapTooltip title="Filter">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <FilterAltOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Settings">
                                    <Typography
                                      sx={{ px: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p four'
                                    >
                                      <SettingsOutlined sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                  <BootstrapTooltip title="Clear">
                                    <Typography
                                      sx={{ pl: "20px" }}
                                      variant="p"
                                      id="tableTitle"
                                      component="div"
                                      className='table-head-p'
                                    >
                                      <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                    </Typography></BootstrapTooltip>
                                </Box>
                              </Toolbar>

                                            <TableContainer className='' sx={{ padding: '10px  30px' }}>
                                            <Box className='table_head_btns'>
                                  <Button className='table_inner_btn'><i className="fa fa-refresh" aria-hidden="true"></i>&nbsp; Resend Notification</Button>
                                  <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Void</Button>
                                  <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
                                </Box>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    className='table-head-color'
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox

                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>

                                                            <TableCell
                                                            >
                                                                Number
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Type
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Name
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Amount
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Status
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Address
                                                            </TableCell>

                                                            <TableCell
                                                            >
                                                                Payment Creation Date
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Description
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Attachment
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                            <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                            <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                           <TableCell><Box sx={{display:'flex',alignItems:'center',color:'rgba(0, 0, 0, 0.87)'}}><LaunchOutlinedIcon sx={{fontSize:'16px',marginRight:1,color:'#0e2e7d'}}/> 2928</Box></TableCell>
                                                           <TableCell >Invoice to</TableCell>
                                                            <TableCell>Nelson</TableCell>
                                                            <TableCell> $5,000.00</TableCell>
                                                            <TableCell>Unpaid</TableCell>
                                                            <TableCell >hj*****@*****om</TableCell>
                                                            <TableCell>2/11/24 11:21 PM GMT+5</TableCell>
                                                            <TableCell>Pa*****w</TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' />
                                        </Paper>
                                    </Box>
                                </Box>
                            </Box>
            </Box>
            <Box className='inner-section' display={display == false ? "none" : "block"} >
              <Loader display={display} />
            </Box>

          </Box>
        </Box>
      </Box>

      <ToastContainer />
    </>
  )
}
export default SendMulitpleInvoices