import { Box, Button, Container, FormLabel, Grid, List, Radio, RadioGroup, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment/moment';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Alert from '@mui/material/Alert';
import Loader from './Layouts/Loader.js'
import {BootstrapDialog,StyledTabs,StyledTab,BootstrapTooltip} from './commonStyle/muiCustomStyle.js'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AccountCards = () => {

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const [bankData, setBankData] = useState([])
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [bankId, setBankId] = useState("");
  const [openAddAccountPopup, setOpenAddAccountPopup] = useState(false);
  const [routingNumber, setRoutingNumber] = useState("");
  const [routingNumberError, setRoutingNumberError] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [confirmAccountNumberError, setConfirmAccountNumberError] = useState(false);
  const [accountType, setAccountType] = useState('CHECKING');
  const [sameAccountError, setSameAccountError] = useState(false)
  const [display,setDisplay] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const handleAddAccount = () => {
    setOpenAddAccountPopup(true);
  }

  const handleAccountPopupClose = () => {
    setOpenAddAccountPopup(false);
  }

  const handleEditName = (name, bankId) => {
    setOpen(true);
    setName(name);
    setBankId(bankId)
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handleRoutingNumber = (e) => {
    setRoutingNumberError(false);
    setRoutingNumber(e.target.value);
  }

  const handleAccountNumber = (e) => {
    setAccountNumberError(false);
    setSameAccountError(false);
    setAccountNumber(e.target.value);
  }

  const handleConfirmAccountNumber = (e) => {
    setConfirmAccountNumberError(false);
    setSameAccountError(false);
    setConfirmAccountNumber(e.target.value);
  }

  const handleAccountTypeChange = (event) => {
   setAccountType(event.target.value);
  };

  useEffect(() => {
    getbankData();
  }, [1]);

 

  const getbankData = async () => {
    setDisplay(true);  
    let config = {
      method: 'GET',
      url: 'https://sandbox.checkbook.io/v3/account/bank',
      headers: {
        'accept': 'application/json',
        'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {

        setBankData(response?.data?.banks);
      //  setDisplay(false)
      })
      .catch((error) => {
       // setDisplay(false)
        console.log(error);
      });
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }


  const updateName = () => {

    if(name){
     
    
    let data = JSON.stringify({
      name: name

    });



    let config = {
      method: 'PUT',
      url: `https://sandbox.checkbook.io/v3/account/bank/${bankId}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {

        if (response?.status === 200) {
          toast.success("Name Updated Sucessfully.")
          setOpen(false);
          getbankData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      toast.error("Please Fill The Name.")
    }

  }


  const handleValidation = ()=>{
    let formIsValid = true;
    if(!routingNumber){
      formIsValid = false;
      setRoutingNumberError(true);
    }
    if(!accountNumber){
      formIsValid = false;
      setAccountNumberError(true);
    }
    if(!confirmAccountNumber){
      formIsValid = false;
      setConfirmAccountNumberError(true);
    }

    if (confirmAccountNumber && accountNumber !== confirmAccountNumber) {
      formIsValid = false;
      setSameAccountError(true)
  }
    return formIsValid;
  }


  const handleAddAccountManual = ()=>{
    if(handleValidation()){
      // let data = JSON.stringify({
      //   account: accountNumber,
      //   routing: routingNumber,
      //   type : accountType
  
      // });
  
  
  
      // let config = {
      //   method: 'POST',
      //   url: 'https://sandbox.checkbook.io/v3/account/bank',
      //   headers: {
      //     accept: 'application/json',
      //     'content-type': 'application/json',
      //     Authorization: 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR'
      //   },
      //   data: data
      // };
  
      // axios.request(config)
      //   .then((response) => {
  
      //     if (response?.status === 201) {
      //       toast.success("Added Sucessfully.")
      //       //setOpen(false);
      //       //getbankData();
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  }

  useEffect(()=>{
    if(bankData.length){
       setDisplay(false);
    }
   
  },[bankData]);


  return (
    <>
      <Navbar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
          <Box>
            <Box className='upper-bluearea'>
              <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
            </Box>

            <Box className='inner-section' display={display==false ? "block":"none"}>
              <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500' }}>Accounts and Cards</Typography>
              <Box className='forever_table' >
                <Box className='dashboard_table' sx={{ pt: '20px' }}>
                  <Box sx={{ width: '100%' }}>
                    <Box>

                      <StyledTabs sx={{ height: '60px', display: 'flex', alignItems: 'center' }} value={value} onChange={handleTabChange} aria-label="basic tabs example" className="indicator" >
                        <StyledTab label="Bank Accounts" {...a11yProps(0)} style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<HomeOutlinedIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} className='tabs_style ' iconPosition="start" />
                        <StyledTab label="Debit Cards" {...a11yProps(1)} style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<PaymentOutlinedIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} className='tabs_style ' iconPosition="start" />
                        <StyledTab label="Virtual Cards" {...a11yProps(2)} style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<CloudUploadOutlinedIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} className='tabs_style ' iconPosition="start" />
                        <StyledTab label="Wallet Accounts" {...a11yProps(3)} style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<AccountBalanceWalletOutlinedIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} className='tabs_style ' iconPosition="start" />
                        <StyledTab label="Zelle Accounts" {...a11yProps(4)} style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<PersonOutlinedIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} className='tabs_style ' iconPosition="start" />
                      </StyledTabs>
                    </Box>
                    <CustomTabPanel value={value} index={0} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%', }} >
                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                          <TableContainer className='' sx={{ padding: '10px  30px' }}>
                            <Box className='table_head_btns users_marketplace'>
                              <Button className='table_inner_btn add_user' sx={{ marginRight: '10px' }} onClick={handleAddAccount} ><i className="fa fa-add" aria-hidden="true"></i>&nbsp; Add Account</Button>
                              <Button className='table_inner_btn'><i className="fa fa-edit gray-edit" aria-hidden="true"></i>&nbsp;Verify</Button>
                              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Delete</Button>

                            </Box>
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              className='table-head-color'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox className='checkbox_table' />
                                  </TableCell>

                                  <TableCell sx={{ width: '100px' }}>
                                    Default  <i className='fa-solid fa-circle-question'></i>
                                  </TableCell>
                                  <TableCell sx={{ width: '100px' }}>
                                    Billing <i className='fa-solid fa-circle-question'></i>
                                  </TableCell>
                                  <TableCell>
                                    Bank
                                  </TableCell>
                                  <TableCell>
                                    Account
                                  </TableCell>
                                  <TableCell>
                                    Type
                                  </TableCell>
                                  <TableCell>
                                    Status
                                  </TableCell>
                                  <TableCell>
                                    Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {
                                  bankData.map((v, i) => {

                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        sx={{ cursor: 'pointer' }}
                                      >
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            color="primary"
                                            className='checkbox_table'

                                          />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            color="primary"
                                            className='checkbox_table'
                                            checked={v?.default}
                                          />
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            color="primary"
                                            className='checkbox_table'
                                            checked={v?.billing}
                                          />
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'uppercase' }}>Acme federal crediy union</TableCell>
                                        <TableCell>{v?.account ? '****' + v?.account.slice(-4) : "-"}</TableCell>
                                        <TableCell>{capitalizeFirstLetter(v?.type)}</TableCell>
                                        <TableCell >{capitalizeFirstLetter(v?.status)}</TableCell>
                                        <TableCell><i className='fa fa-edit' onClick={(e) => { handleEditName(v?.name, v?.id) }}></i>&nbsp;{(v?.name) ? v?.name : "-"}</TableCell>

                                      </TableRow>
                                    )
                                  })
                                }

                              </TableBody>
                            </Table>
                          </TableContainer>
                          {/* <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' /> */}
                        </Paper>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%', }} >
                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                          <TableContainer className='' sx={{ padding: '10px  30px' }}>
                            <Box className='table_head_btns users_marketplace'>
                              <Button className='table_inner_btn add_user' sx={{ marginRight: '10px' }}><i className="fa fa-add" aria-hidden="true"></i>&nbsp; Add Account</Button>
                              <Button className='table_inner_btn'><i className="fa fa-edit" aria-hidden="true"></i>&nbsp;Verify</Button>
                              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Delete</Button>

                            </Box>
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              className='table-head-color'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox className='checkbox_table' />
                                  </TableCell>
                                  <TableCell>
                                    Card Number
                                  </TableCell>
                                  <TableCell>
                                    Expiration Date
                                  </TableCell>
                                  <TableCell>
                                    Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              {/* <TableBody>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'
                                    />
                                  </TableCell>
                                  <TableCell sx={{ textTransform: 'uppercase' }}>Acme federal crediy union</TableCell>
                                  <TableCell>****1452</TableCell>
                                  <TableCell>Business</TableCell>
                                  <TableCell >Verified</TableCell>
                                  <TableCell><i className='fa fa-edit'></i></TableCell>

                                </TableRow>
                              </TableBody> */}
                            </Table>
                          </TableContainer>
                          {/* <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' /> */}
                        </Paper>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%', }} >
                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                          <TableContainer className='' sx={{ padding: '10px  30px' }}>
                            <Box className='table_head_btns users_marketplace'>
                              <Button className='table_inner_btn add_user' sx={{ marginRight: '10px' }}><i className="fa fa-add" aria-hidden="true"></i>&nbsp; Add Account</Button>
                              <Button className='table_inner_btn'><i className="fa fa-edit" aria-hidden="true"></i>&nbsp;Verify</Button>
                              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Delete</Button>

                            </Box>
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              className='table-head-color'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox className='checkbox_table' />
                                  </TableCell>
                                  <TableCell>
                                    Card Number
                                  </TableCell>
                                  <TableCell>
                                    Expiration Date
                                  </TableCell>
                                  <TableCell>
                                    Balance
                                  </TableCell>
                                  <TableCell>
                                    CVV
                                  </TableCell>
                                  <TableCell>
                                    Pin Number
                                  </TableCell>
                                  <TableCell>
                                    Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              {/* <TableBody>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'
                                    />
                                  </TableCell>
                                  <TableCell sx={{ textTransform: 'uppercase' }}>Acme federal crediy union</TableCell>
                                  <TableCell>****1452</TableCell>
                                  <TableCell>Business</TableCell>
                                  <TableCell >Verified</TableCell>
                                  <TableCell><i className='fa fa-edit'></i></TableCell>

                                </TableRow>
                              </TableBody> */}
                            </Table>
                          </TableContainer>
                          {/* <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' /> */}
                        </Paper>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%', }} >
                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                          <TableContainer className='' sx={{ padding: '10px  30px' }}>
                            <Box className='table_head_btns users_marketplace'>
                              <Button className='table_inner_btn add_user' sx={{ marginRight: '10px' }}><i className="fa fa-add" aria-hidden="true"></i>&nbsp; Add Account</Button>
                              <Button className='table_inner_btn'><i className="fa fa-edit" aria-hidden="true"></i>&nbsp;Verify</Button>
                              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Delete</Button>

                            </Box>
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              className='table-head-color'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox className='checkbox_table' />
                                  </TableCell>

                                  <TableCell>
                                    Name
                                  </TableCell>
                                  <TableCell>
                                    Date
                                  </TableCell>
                                  <TableCell>
                                    Balance
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              {/* <TableBody>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'
                                    />
                                  </TableCell>
                                  <TableCell sx={{ textTransform: 'uppercase' }}>Acme federal crediy union</TableCell>
                                  <TableCell>****1452</TableCell>
                                  <TableCell>Business</TableCell>
                                  <TableCell >Verified</TableCell>
                                  <TableCell><i className='fa fa-edit'></i></TableCell>

                                </TableRow>
                              </TableBody> */}
                            </Table>
                          </TableContainer>
                          {/* <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' /> */}
                        </Paper>
                      </Box>
                    </CustomTabPanel>
                    {/* Multi Party Payment section */}
                    <CustomTabPanel value={value} index={4} sx={{ p: '0' }} className="panelInnerSide">
                      <Box sx={{ width: '100%', }} >
                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                          <TableContainer className='' sx={{ padding: '10px  30px' }}>
                            <Box className='table_head_btns users_marketplace'>
                              <Button className='table_inner_btn add_user' sx={{ marginRight: '10px' }}><i className="fa fa-add" aria-hidden="true"></i>&nbsp; Add Account</Button>
                              <Button className='table_inner_btn'><i className="fa fa-edit" aria-hidden="true"></i>&nbsp;Verify</Button>
                              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Delete</Button>

                            </Box>
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              className='table-head-color'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell padding="checkbox">
                                    <Checkbox className='checkbox_table' />
                                  </TableCell>


                                  <TableCell>
                                    Name
                                  </TableCell>
                                  <TableCell>
                                    Date
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              {/* <TableBody>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'

                                    />
                                  </TableCell>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      className='checkbox_table'
                                    />
                                  </TableCell>
                                  <TableCell sx={{ textTransform: 'uppercase' }}>Acme federal crediy union</TableCell>
                                  <TableCell>****1452</TableCell>
                                  <TableCell>Business</TableCell>
                                  <TableCell >Verified</TableCell>
                                  <TableCell><i className='fa fa-edit'></i></TableCell>

                                </TableRow>
                              </TableBody> */}
                            </Table>
                          </TableContainer>
                          {/* <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' /> */}
                        </Paper>
                      </Box>
                    </CustomTabPanel>
                  </Box>


                </Box>
              </Box>
            </Box>
            <Box className='inner-section' display={display==false ? "none":"block"} >
                  <Loader display={display} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <BootstrapDialog
          className='recurring_box'
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{}}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className=''  >
            <img src={cbLogo} className="modal_icon" />
            <Typography className='modalhead'>Edit the account name</Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent >

            <Box sx={{ my: '1rem' }}>
              <InputLabel >
                Enter the name for this bank account
              </InputLabel>
              <Box className='input_a'>
                <Typography variant="div" className='icon_style_a'>
                  <i class="fa-solid fa-a"></i>
                </Typography>
                <FormControl variant="outlined" fullWidth  >
                  <OutlinedInput value={name} onChange={handleName} />
                </FormControl>
              </Box>

            </Box>

            <Box height={30}>

            </Box>
          </DialogContent>
          <DialogActions className='user_bottom' >
            <Button autoFocus onClick={handleClose} className='recurring_modal_cancel' >
              Cancel
            </Button>
            <Button autoFocus className='recurring_modal_btn' onClick={updateName}>
              Save
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Box>

      {/* Manual Verfiaction popup */}
      <Box>
        <Dialog
          className='recurring_box'
          onClose={handleAccountPopupClose}
          aria-labelledby="customized-dialog-title"
          open={openAddAccountPopup}
          sx={{}}
          fullWidth
          maxWidth="sm"
          scroll='body'
        >
          <DialogTitle className=''  >
            <img src={cbLogo} className="modal_icon" />
            <Typography className='modalhead'>Manual Verification</Typography>

            <FormControl sx={{ my: 0, minWidth: 120 }} size="small">
              <Select
                value="United States"
              >
                <MenuItem value="United States">United States</MenuItem>
                <MenuItem value="">Demo</MenuItem>
                <MenuItem value="">Demo</MenuItem>
              </Select>
            </FormControl>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleAccountPopupClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent >

            <Box >
              <InputLabel className='color-label-black'>
                Routing number
              </InputLabel>
              <Box className='input_a style_height' sx={{margin:"5px 0 15px 0"}}>
                <FormControl variant="outlined" fullWidth  >
                  <OutlinedInput placeholder='Enter number' className={routingNumberError?"error":""} type='number' value={routingNumber} onChange={handleRoutingNumber} />
                </FormControl>
              </Box>

              <Box sx={{ my: '1rem' }}>
                <Alert icon={false} severity="success" className='modal_greenarea'>
                  acme fedreal credit union
                </Alert>
              </Box>
            </Box>

            <Box >
              <InputLabel className='color-label-black'>
                Account number
              </InputLabel>
              <Box className='input_a style_height'  sx={{margin:"5px 0 15px 0"}}>
                <FormControl variant="outlined" fullWidth  >
                  <OutlinedInput placeholder='Enter number' className={accountNumberError?"error":""} type='number' value={accountNumber} onChange={handleAccountNumber} />
                </FormControl>
              </Box>
            </Box>

            <Box >
              <InputLabel className='color-label-black'>
                Confirm Account Number &emsp;<i className="fa-solid fa-circle-question"></i>
              </InputLabel>
              <Box className='input_a style_height' sx={{margin:"5px 0 0 0"}}>
                <FormControl variant="outlined" fullWidth  >
                  <OutlinedInput placeholder='Enter number' type='number' className={confirmAccountNumberError?"error":""} value={confirmAccountNumber} onChange={handleConfirmAccountNumber} />
                </FormControl>
                
              </Box>
              {sameAccountError ? <p className="error-message">Confirm email should be same as email.</p> : ""}
            </Box>
            <Box>

              <FormControl sx={{margin:"15px 0 0 0"}}>
                <FormLabel id="demo-row-radio-buttons-group-label" className='color-label-black'> Account type </FormLabel>
                <FormControlLabel
                  value="CHECKING"
                  control={<Radio />}
                  label="Personal Checking"
                  className='recurring_radio account_radio'
                  checked={accountType === 'CHECKING'}
                  onChange={handleAccountTypeChange}
                />
                <FormControlLabel
                  value="SAVINGS"
                  control={<Radio />}
                  label="Personal Savings"
                  className='recurring_radio account_radio'
                  checked={accountType === 'SAVINGS'}
                  onChange={handleAccountTypeChange}
                />
                <FormControlLabel
                  value="BUSINESS"
                  control={<Radio />}
                  label="Business Account"
                  className='recurring_radio account_radio'
                  checked={accountType === 'BUSINESS'}
                  onChange={handleAccountTypeChange}
                />
              </FormControl>
            </Box>


            <Box sx={{ my: '1rem' }} className="modal_bluearea">
              <Typography>We pre-filled the bank account numbers, simply click the submit
                button. If you want to use a live account feel free to change the values. All
                transactions in Sandbox mode are not settled with the bank they remain in our database.
              </Typography>
            </Box>
            <Box sx={{ my: '1rem' }}>
              <Typography>
                Where can I find this informtion?
              </Typography>
            </Box>
            <Box height={10}>

            </Box>
          </DialogContent>
          <DialogActions className='user_bottom' >
            <Button autoFocus onClick={handleAccountPopupClose} className='recurring_modal_cancel' >
              Back
            </Button>
            <Button autoFocus className='recurring_modal_btn' onClick={handleAddAccountManual} >
              Submit
            </Button >
          </DialogActions>
        </Dialog>
      </Box>

      <ToastContainer />


    </>
  )
}

export default AccountCards


