import { Box, Button, Container, Grid, Typography, FormControl, OutlinedInput } from '@mui/material'
import React from 'react';
import { useState, useEffect } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {BootstrapTooltip} from './commonStyle/muiCustomStyle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';
import currencyFormatter from 'currency-formatter';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';

function AllPaymentList(props){
    
    
    const [uname, setUname] = useState("");
    const [uaddress, setUaddress] = useState("");
    const [unumber, setUnumber] = useState("");
    const [ustatus, setUstatus] = useState("");
    const [uamount, setUamount] = useState("");
    const [urecurring, setUrecurring] = useState("");
    const [upaymentCreateDate, setUpaymentCreateDate] = useState("");
    const [upaymentReceiveDate, setUpaymentReceiveDate] = useState("");
    const [udescription, setUdescription] = useState("");
    const [uattachment, setUattachment] = useState("");
    const [ucheckId, setUcheckId] = useState("");
    const [uimage, setUimage] = useState("");
     
    const [openDetailsPoupShow, setOpenDetailsPoupShow] = useState(false); 

    const addressFormatter = (address) => {
        if(address.hasOwnProperty('city')){
          return address?.line_1+(address?.line_2?', '+address?.line_1+', ':'')+(address?.city?', '+address?.city:'')+(address?.state?', '+address?.state:'')+(address?.zip?', '+address?.zip:'')
        }else{
          return address;
        }
      }

      const openCheckDetailsPopup = (data) => {

        setOpenDetailsPoupShow(true);
        if (data.name != undefined) { setUname(data.name) }
        // if(data.address !=undefined){setUaddress("");
        if (data.number != undefined) { setUnumber(data.number) }
        if (data.status != undefined) { setUstatus(data.status) }
        if (data.amount != undefined) { setUamount(data.amount) }
        // if(data.recurring !=undefined){setUrecurring(data.recurring)}
        if (data.date != undefined) { setUpaymentCreateDate(data.date) }
        //if(data.Paymentreceive !=undefined){setUpaymentReceiveDate("")}
        if (data.description != undefined) { setUdescription(data.description) }
        //setUattachment();
        if (data.id != undefined) { setUcheckId(data.id) }
    
        if (data.id != undefined) { setUimage(data.image_uri) }
      }
      const handleCloseDetailsPopup = (e) => {
        setOpenDetailsPoupShow(false);
      }

      const handlePageChange =(e,value) =>{
        props.handleChange(e,value)
      }
    

return(
    <>
    <Box className='forever_table' >
    <Box className='dashboard_table  '>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
          <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
            <Box sx={{ display: 'flex', }}>
              <Typography
                sx={{ paddingRight: "20px" }}
                variant="p"
                id="tableTitle"
                component="div"
                className='table-head-p'
              >
                <i class="fas fa-calendar-alt"></i> &nbsp; All Transactions &nbsp; <i class="fa-solid fa-angle-down"></i>
              </Typography>
              <Typography
                sx={{ px: '10px' }}
                variant="p"
                id="tableTitle"
                component="div"
                className='table-head-p'
              >
                <i class="fa-regular fa-file"></i> &nbsp; Export to Excel CSV
              </Typography>
              <Typography
                sx={{ px: "10px" }}
                variant="p"
                id="tableTitle"
                component="div"
                className='table-head-p'
              >
                <TextField placeholder='Search' className='searchHeadTable' />
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
              <Typography
                sx={{ px: "20px" }}
                variant="p"
                id="tableTitle"
                component="div"
                className='table-head-p'
              >
                <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
              </Typography>
              <BootstrapTooltip title="Filter">
                <Typography
                  sx={{ px: "20px" }}
                  variant="p"
                  id="tableTitle"
                  component="div"
                  className='table-head-p'
                >
                  <FilterAltOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                </Typography></BootstrapTooltip>
              <BootstrapTooltip title="Settings">
                <Typography
                  sx={{ px: "20px" }}
                  variant="p"
                  id="tableTitle"
                  component="div"
                  className='table-head-p four'
                >
                  <SettingsOutlined sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                </Typography></BootstrapTooltip>
              <BootstrapTooltip title="Clear">
                <Typography
                  sx={{ pl: "20px" }}
                  variant="p"
                  id="tableTitle"
                  component="div"
                  className='table-head-p'
                >
                  <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                </Typography></BootstrapTooltip>
            </Box>
          </Toolbar>

          <TableContainer className='' sx={{ padding: '10px  30px' }}>
            <Box className='table_head_btns'>
              <Button className='table_inner_btn'><i className="fa fa-refresh" aria-hidden="true"></i>&nbsp; Resend Notification</Button>
              <Button className='table_inner_btn' sx={{ marginLeft: '10px' }}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp; Void</Button>
              <Typography variant='span' sx={{ fontSize: '14px' }}> &ensp; 0 selected / 1 item</Typography>
            </Box>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              className='table-head-color'
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox

                      className='checkbox_table'
                    />
                  </TableCell>

                  <TableCell
                  >
                    Number
                  </TableCell>
                  <TableCell
                  >
                    Type
                  </TableCell>
                  <TableCell
                  >
                    Name
                  </TableCell>
                  <TableCell
                  >
                    Amount
                  </TableCell>
                  <TableCell
                  >
                    Status
                  </TableCell>

                  <TableCell
                  >
                    Address
                  </TableCell>
                  <TableCell
                  >
                    Payment Creation Date
                  </TableCell>
                  <TableCell
                  >
                    Description
                  </TableCell>
                  <TableCell
                  >
                    Attachment
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  props.data.map((v, i) => {
                    return (
                      <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{ cursor: 'pointer' }}
                      className={v?.status === "VOID"?"darkGreyColor":""}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            className='checkbox_table'
                          />
                        </TableCell>
                        <TableCell><i className='fa fa-edit' onClick={(e) => { openCheckDetailsPopup(v) }} ></i>&nbsp;{v?.number}</TableCell>
                        <TableCell >Check to</TableCell>
                        <TableCell>{v?.name}</TableCell>
                        <TableCell>{currencyFormatter.format(v?.amount, { code: 'USD' })}</TableCell>
                        <TableCell>{v?.status}</TableCell>
                        <TableCell >{addressFormatter(v?.recipient)}</TableCell>
                        <TableCell>{v?.date}</TableCell>
                        <TableCell className='word-wrap'>{v?.description}</TableCell>
                        <TableCell sx={{textAlign:'center'}}> <BootstrapTooltip title="Download PDF"><Link to={"#"} download="#" target="_blank" className='link-style' ><DownloadForOfflineOutlinedIcon  /></Link></BootstrapTooltip></TableCell>

                      </TableRow>
                    )
                  })
                }

              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={props.pageCount} page={props.page} onChange={handlePageChange} className='pagination_dashboard' />
        </Paper>
      </Box>
    </Box>
  </Box>
     

     <Box sx={{ background: '#fff' }} fullScreen>
     <Dialog
       className='recurring_box'
       onClose={handleCloseDetailsPopup}
       aria-labelledby="customized-dialog-title"
       open={openDetailsPoupShow}
       sx={{ overflowY: 'scroll' }}
       fullWidth
       maxWidth="sm"
       scroll='body'
     >
       <DialogTitle className=''  >
         <img src={cbLogo} className="modal_icon" />
         <Typography className='modalhead'>Payment detail</Typography>
       </DialogTitle>
       <IconButton
         aria-label="close"
         onClick={handleCloseDetailsPopup}
         sx={{
           position: 'absolute',
           right: 8,
           top: 8,
           color: (theme) => theme.palette.grey[500],
         }}
       >
         <CloseIcon />
       </IconButton>
       <DialogContent  >
         {/* <Box gutterBottom>
         <img src={uimage} className='img-fluid' />
         </Box> */}
         <Grid container spacing={2} >
           <Grid item xs={12} md={12}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}} >Name</Typography> : {uname} </Typography>
           </Grid>
           <Grid item xs={12} md={12}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Address</Typography> : {uaddress}   </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Number</Typography> : {unumber}  </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Status</Typography> : {ustatus}  </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Amount</Typography> : {uamount}  </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Recurring</Typography> : {urecurring}   </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Payment Creation Date</Typography> : {upaymentCreateDate}   </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Payment Receive Date &nbsp;</Typography> : {upaymentReceiveDate}  </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Description</Typography> :  {udescription}  </Typography>
           </Grid>
           <Grid item xs={12} md={6}>
             <Typography className='color-label-black' component='span'><Typography component='span' sx={{fontWeight:'600'}}>Attachment</Typography> :   </Typography>
           </Grid>
           {/* <Grid item xs={12} md={12}>
             <Typography><strong>Check ID</strong> : {ucheckId}   </Typography>
           </Grid> */}
         </Grid>

       </DialogContent>
       <DialogActions sx={{ height: '85px', display: 'flex', justifyContent: 'start' }}>
         <Button autoFocus onClick={handleCloseDetailsPopup} className='recurring_modal_btn' sx={{marginLeft:'8px'}} >
           Close
         </Button>
       </DialogActions>
     </Dialog>
   </Box>
   </>


)
} 
export default AllPaymentList;