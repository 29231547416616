import { Box, Button, Container, Grid, Typography, FormControl, OutlinedInput } from '@mui/material'
import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import LogoIcon from '../assets/images/dashboard_icon.svg'
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import mainLogo from '../assets/images/main-logo.jpg';
import checkbook from '../assets/images/checkbook.png';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ModalLogo from '../assets/images/viable-logo.jpeg'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import DirectPaymentModel from './directPaymentModel';
import Loader from './Layouts/Loader.js'
import currencyFormatter from 'currency-formatter';
import numberToText from 'number-to-text';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import rtpimg from '../assets/images/rtp.png';
import achimg from '../assets/images/ach.png';
import AllPaymentList from './allPaymentList.js';
import { IOSSwitch, BootstrapDialog, BootstrapTooltip, StyledTab, StyledTabs, CustomTabPanel } from './commonStyle/muiCustomStyle.js'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentsIcon from '@mui/icons-material/Payments';
require('number-to-text/converters/en-us');




function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
      <Box sx={{ width: '100%', my: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ height: '10px', borderRadius: '4px' }} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const SendPayment = () => {
  const [bank, setBank] = React.useState("");
  const [date, setDate] = React.useState("0");
  const [month, setMonth] = React.useState("0");
  const [year, setYear] = React.useState("0");
  const [duration, setDuration] = React.useState(0);
  const [payeeName, setPayeeName] = useState("");
  const [payeeEmail, setPayeeEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  //const [sourceAccount, setSourceAccount] = useState("");
  const [selectedBank, setSelectedBank] = useState("");



  const [data, setData] = useState([]);
  const [baseSix, setBaseSix] = useState("");

  const [fileData, setFileData] = useState([])
  const [fileName, setFileName] = useState("")
  const [fileType, setFileType] = useState("")
  const [fileError, setFileError] = useState(false)
  const [fileErrorMsg, setFileErrorMsg] = useState(false)

  const [bankData, setBankData] = useState([])
  const [selectedBankName, setSelectedBankName] = useState("");
  const [hideShow, setHideShow] = useState(false);
  const [recurringDatas, setRecurringDatas] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [checkNo, setCheckNo] = useState(0)

  const [display, setDisplay] = useState(false);

  const handleDuration = (e) => {
    setDuration(e.target.value);
  };
  const handleBank = (e) => {
    //setBank(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleMonth = (e) => {
    setMonth(e.target.value);
  };
  const handleYear = (e) => {
    setYear(e.target.value);
  };


  const [progress, setProgress] = React.useState(10);

  const [open, setOpen] = useState(false);
  const [muiSwitchChecked, setMuiSwitchChecked] = useState(false);
  const [muiApprovalChecked, setMuiApprovalChecked] = useState(false);


  const handleClickOpen = (event) => {
    // console.log("event.target.checked",event.target.checked)
    setMuiSwitchChecked(event.target.checked);
    setOpen(event.target.checked);
    setHideShow(event.target.checked)
    setRecurringDatas('Monthly');
  };
  const handleClose = () => {
    setOpen(false);
    // setMuiSwitchChecked(false);
  };

  const handleClickApproval = (e) => {
    setMuiApprovalChecked(e.target.checked)
  }


  const handleSave = () => {
    setOpen(false);

    //let reccuringData=  selectedValue+", "+ year+"-"+month+"-"+date +","+ duration
    let dates = "";
    if (year != "0" && month != "0" && date != "0") {
      dates = "from " + year + "-" + month + "-" + date;
    } else {
      setYear(0); setMonth(0); setDate(0);
    }
    let dur = "";
    if (duration != 0) {
      dur = duration + " payments"
    } else {
      setDuration(0);
    }

    let reccuringData = selectedValue + (dates != "" ? "," + dates : "") + (dur != "" ? "," + dur : "");
    setRecurringDatas(reccuringData);

  };



  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [openDetails, setOpenDetails] = useState(false);

  const openDetailsPoup = () => {
    setOpenDetails(true);
  }

  const handleCloseDetails = () => {
    setOpenDetails(false);
  }

  const handlePayeeName = (e) => {
    setPayeeName(e.target.value);
  }

  const hadlePayeeEmail = (e) => {
    setPayeeEmail(e.target.value);
  }
  const [bkName, setBkName] = useState("Bank");

  const handelSelectBank = (e) => {
    setSelectedBank(e.target.value);

    //  console.log("test",e.target)

    let xdata = bankData.filter(x => {
      if (x.id == e.target.value) {
        return x;
      }
    });

    //console.log("xdata",xdata);

    setBkName(xdata[0]?.name);
    //setSelectedBankName(name);

  }

  const [digit, setDigit] = useState(0);
  const [fraction, setFraction] = useState("00");


  /*const handleAmount = (e) => {
    if(e.target.value >=0 && e.target.value <=200000){
    setAmount(e.target.value);
    
    if(e.target.value>=1){
    let amt = e.target.value.split(".");
    setDigit(amt[0]);
    if(amt.length>1){
      let fr = amt[1].slice(0, 2) 
      if(fr.length<2){
       fr= fr+"0";
      }
      setFraction(fr);
    }else{
      setFraction("00");
    }
   }else{

   }
   }
  }  */

  const handleAmount = (e) => {
    if (e.target.value >= 0 && e.target.value <= 200000) {
      setAmount(e.target.value);
    }
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
  }

  const [dataDisable, setDataDisable] = useState(true);

  useEffect(() => {
    // console.log("testt",payeeEmail,payeeName,amount)
    if (payeeEmail != "" && payeeName != "" && amount != "" && validateEmail(payeeEmail)) {
      setDataDisable(false);
    } else {
      setDataDisable(true);
    }
  }, [payeeEmail, payeeName, amount]);







  const handelSendPayment = () => {
    setDisplay(true);

    let data = {

      /*  
      attachment: {
        content:'',
        filename: 'attachment.pdf'
      },
      pin: {description: 'Please enter your payments pin #', value: '1234'},
      remittance_advice: [{amount: 34, date: '2024-02-06', description: 'Test', id: 'qqqq'}],
      amount: 5.23,
      description: 'Example memo',
      name: 'Widgets Inc.',
      recipient: 'testing@checkbook.io'
      */

      amount: amount,
      description: description,
      name: payeeName,
      recipient: payeeEmail,

    }
    if (baseSix) {
      data['attachment'] = {
        content: baseSix.replace("data:application/pdf;base64,", ""),
        filename: 'attachment.pdf'
      }
    }
    if (selectedBank) {
      data['account'] = selectedBank;
    }

    if (hideShow) {
      data["interval"] = selectedValue;
      if (year != "0" && month != "0" && date != "0") {
        data['start_date'] = year + "-" + date + "-" + month;
      }
      if (duration != "") {
        data['duration'] = duration;
      }
    }


    let dataMain = JSON.stringify(data);

    let config = {
      method: 'post',
      url: 'https://sandbox.checkbook.io/v3/check/digital',
      headers: {
        'accept': 'application/json',
        'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      },
      data: dataMain
    };



    axios.request(config)
      .then((response) => {
        let res = response.data
        if (res.status) {
          getData();
          toast.success("Added Sucessfully.");
          setDisplay(false);
        }
      })
      .catch((error) => {
        setDisplay(false);
        //    console.log(error);
        toast.error(error?.response?.data?.error);

      });


  }


  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };



  useEffect(() => {
    getData();
    getbankData();
  }, []);


  useEffect(() => {
    if (payeeName && payeeEmail && amount) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true);
    }
  }, [payeeName, payeeEmail, amount]);


  useEffect(() => {
    if (fileData.length > 0) {
      getBase64(fileData[0]).then(result => {
        setBaseSix(result);
      });
    }
  }, [fileData]);

  const [perPageData, setPerPageData] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const getData = async () => {

    setDisplay(true);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://sandbox.checkbook.io/v3/check?page=${page}&per_page=${perPageData}`,
      headers: {
        'accept': 'application/json',
        'Authorization': ' e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {
        //console.log("data",response.data.checks[0].number)
        setData(response.data?.checks);
        setPageCount(response.data?.pages)
        setCheckNo(parseInt(response?.data?.checks[0]?.number) + 1)
        setDisplay(false);
      })
      .catch((error) => {
        setDisplay(false);
        console.log(error);

      });

  }

  useEffect(() => {
    getData();
  }, [page])


  const getbankData = async () => {
    let config = {
      method: 'GET',
      url: 'https://sandbox.checkbook.io/v3/account/bank',
      headers: {
        'accept': 'application/json',
        'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {
        console.log("test", response.data.banks);
        setBankData(response?.data?.banks);
        setSelectedBank(response?.data?.banks[0]?.id)
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const allowedTypes = ["application/pdf"];
  const MAX_FILE_SIZE_IMAGE = 7168 // 7MB

  const handleFile = (e) => {
    const selectedFile = e.target.files;
    setFileError(false)
    setFileErrorMsg("");
    setFileData(selectedFile)
    let file_name = selectedFile[0]?.name;
    let file_type = selectedFile[0]?.type;
    setFileName(file_name)
    if (file_type === "application/pdf") {
      setFileType("pdf")
    }
    else {
      setFileType("")
    }
    if (selectedFile.length && !allowedTypes.includes(selectedFile[0]?.type)) {
      setFileError(true)
      setFileErrorMsg("Only PDF images are allowed.");
    }
    if (selectedFile.length && (selectedFile[0].size / 1024) > MAX_FILE_SIZE_IMAGE) {
      setFileErrorMsg("File size is greater than 2 MB");
      setFileError(true)
    }
  }

  const [selectedValue, setSelectedValue] = useState('Monthly');

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const addressFormatter = (address) => {
    if (address.hasOwnProperty('city')) {
      return address?.line_1 + (address?.line_2 ? ', ' + address?.line_1 + ', ' : '') + (address?.city ? ', ' + address?.city : '') + (address?.state ? ', ' + address?.state : '') + (address?.zip ? ', ' + address?.zip : '')
    } else {
      return address;
    }
  }

  const [directPayment, setDirectPayment] = useState(false);
  const handelOpen = (data) => {
    setDirectPayment(true);
  }

  const handelClose = (data) => {
    setDirectPayment(data);

  }
  const [typeData, setTypeData] = useState("");

  const openDirectPayment = (type) => {
    setDirectPayment(true);
    setTypeData(type);
  }

  const checkEnteredAnount = () => {
    if (amount > 200000.00) {
      setAmount("");
      toast.error("Please enter the amount less than or equal to $200000.00")
    }
  }
  const onNumerInputKeyDown = (event) => {
    if (event.key === "e") event.preventDefault();
    if (event.key === "-") event.preventDefault();
  }

  const processData = (data) => {
    getData();
    restAll();
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const [value, setValue] = React.useState(0);

  const handleAchChange = (event, newValue) => {
    setValue(newValue);
  };

  const restAll = () => {
    setPayeeEmail("");
    setPayeeName("");
    setAmount("");
    setDescription("");
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const openEditModel =() =>{
    setOpen(true) 
 } 

  return (


    <>
      <Navbar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
          <Box>
            <Box className='upper-bluearea'>
              <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
            </Box>
            <Box className='inner-section' display={display == false ? "block" : "none"} >
              <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Send A Payment</Typography>
              <Box className='payment_form_area mb-30'>
                <Grid container spacing={3} >
                  <Grid item xs={12} lg={5} xl={5} >
                    <Box className='payment_form'>
                      <Box>
                        <Box className="icon_input">
                          <Typography variant="div" className='icon_style'>
                            <i className="fa-regular fa-user "></i>
                          </Typography>
                          <FormControl variant="outlined" className='payment_input' fullWidth >
                            <OutlinedInput placeholder="Payee's Name*" value={payeeName} onChange={handlePayeeName} />
                          </FormControl>
                        </Box>

                        <Box className="icon_input">
                          <Typography variant="div" className='icon_style'>
                            <i className="fa-regular fa-envelope"></i>
                          </Typography>
                          <FormControl variant="outlined" className='payment_input' fullWidth>
                            <OutlinedInput placeholder="Payee's email, mobile phone number or mailing address*" value={payeeEmail} onChange={hadlePayeeEmail} />
                          </FormControl>
                        </Box>

                        <Box className="icon_input">
                          <Typography variant="div" className='icon_style'>
                            <i className="fa-solid fa-dollar-sign"></i>
                          </Typography>
                          <FormControl variant="outlined" className='payment_input' fullWidth>
                            {/* <OutlinedInput type="number" placeholder='Amount*' value={amount} onChange={handleAmount} onBlur={checkEnteredAnount} /> */}
                            <OutlinedInput type="number" inputProps={{ maxLength: 6 }} placeholder='Amount*' value={amount} onChange={handleAmount} onBlur={checkEnteredAnount} onKeyDown={onNumerInputKeyDown} />
                          </FormControl>
                        </Box>

                        <Box className="icon_input">
                          <Typography variant="div" className='icon_style'>
                            <i className="fa-solid fa-t"></i>
                          </Typography>
                          <FormControl variant="outlined" className='payment_input' fullWidth>
                            <OutlinedInput placeholder='Description' value={description} onChange={handleDescription} />
                          </FormControl>
                        </Box>

                        <Box className='payment_toggle'>
                          <Typography variant='p' component='p'>Recurring </Typography>
                          <FormControlLabel
                            onChange={handleClickOpen}
                            checked={muiSwitchChecked}
                            control={<IOSSwitch sx={{ marginLeft: '30px' }} />}
                          />
                          <Typography sx={{ textAlign: 'end', fontSize: '14px!important' }}> {(hideShow) ? muiApprovalChecked ? "Can't send a recurring payment when submitting for approval" : recurringDatas : ""} {(hideShow) ? muiApprovalChecked ? "" : <i className='fa fa-edit' onClick={openEditModel}></i> : ""}  </Typography>
                        </Box>

                        <Box sx={{ background: '#fff' }} >
                          <Dialog
                            className='recurring_box'
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            fullWidth
                            maxWidth="sm"
                          >
                            <DialogTitle className=''  >
                              <img src={cbLogo} className="modal_icon" />
                              <Typography className='modalhead'>Recurring payment details</Typography>
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <DialogContent >

                              <Box gutterBottom>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: 'fit-content',
                                }}>
                                  <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Interval <i className="fa-solid fa-circle-question"></i></FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value="Weekly"
                                        control={<Radio />}
                                        label="Weekly"
                                        className='recurring_radio'
                                        checked={selectedValue === 'Weekly'}
                                        onChange={handleRadioChange}
                                      />
                                      <FormControlLabel
                                        value="Monthly"
                                        control={<Radio />}
                                        label="Monthly"
                                        className='recurring_radio'
                                        checked={selectedValue === 'Monthly'}
                                        onChange={handleRadioChange}
                                      />

                                    </RadioGroup>
                                  </FormControl>
                                </Box>

                                <FormLabel>Start date (optional) <i className="fa-solid fa-circle-question"></i></FormLabel>
                                <Grid container className='date_input '>
                                  <Grid md={4}>
                                    <FormControl className='bank_input select-input-height' fullWidth>
                                      <Select
                                        value={month}
                                        onChange={handleMonth}
                                        className='bank_select'
                                        sx={{ margin: '5px 10px 20px 0', height: '42px' }}

                                      >
                                        <MenuItem disabled value="0" >MM</MenuItem>
                                        {[...Array(12)].map((x, i) =>

                                          <MenuItem value={i + 1} key={i + 1}>{i + 1}</MenuItem>
                                        )}


                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid md={4}>
                                    <FormControl className='bank_input' fullWidth>
                                      <Select
                                        value={date}
                                        onChange={handleDate}
                                        className='bank_select'
                                        sx={{ margin: '5px 10px 20px 0', height: '42px' }}
                                      >
                                        <MenuItem disabled value="0" key="0" >DD</MenuItem>
                                        {[...Array(31)].map((x, i) =>

                                          <MenuItem value={i + 1} key={i + 1}>{i + 1}</MenuItem>
                                        )}


                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid md={4}>
                                    <FormControl className='bank_input' fullWidth>
                                      <Select
                                        value={year}
                                        onChange={handleYear}
                                        className='bank_select'
                                        sx={{ margin: '5px 0px 20px 0px', height: '42px' }}
                                      >
                                        <MenuItem disabled value="0">YYYY</MenuItem>
                                        {[...Array(5)].map((x, i) =>

                                          <MenuItem value={i + 2022} key={i}>{i + 2022}</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                <Box>
                                  <FormControl className='bank_input' fullWidth>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Duration (optional) <i className="fa-solid fa-circle-question"></i></FormLabel>
                                    <Select
                                      value={duration}
                                      onChange={handleDuration}
                                      className='bank_select'
                                      sx={{ margin: '5px 0px 0px 0px', height: '42px' }}
                                    >
                                      <MenuItem value="0"> Indefinite</MenuItem>
                                      {[...Array(52)].map((x, i) =>

                                        <MenuItem value={i + 1} key={i}>{i + 1}</MenuItem>
                                      )}

                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                              <Box height={30}>

                              </Box>
                            </DialogContent>
                            <DialogActions>
                              <Button autoFocus onClick={handleSave} className='recurring_modal_btn' sx={{ margin: '5px 10px 10px 0px' }}>
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>



                        <Box className='payment_toggle'>
                          <Typography variant='p' component='p'>Submit for Approval </Typography>
                          <FormControlLabel
                            onChange={handleClickApproval}
                            checked={muiApprovalChecked}
                            control={<IOSSwitch sx={{ marginLeft: '30px' }} />}
                          />
                        </Box>
                        <Box className='payment_bank' >
                          <Typography variant='p' component='p' className='source'>Source bank account </Typography>
                          <FormControl fullWidth className='bank_input'>
                            <Select
                              value={selectedBank}
                              onChange={handelSelectBank}
                              className='bank_select'
                            >
                              <MenuItem disabled value="Select Number">
                                Select Number
                              </MenuItem>
                              {bankData?.map((v, i) => (
                                <MenuItem value={v.id}  >*****{v?.account} {(v?.name != null) ? "(" + v?.name + ")" : ""} </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className="rtp-ach-buttons">
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <Button className={dataDisable ? 'rtp_button disableBtn' : 'rtp_button'} onClick={e => { openDirectPayment('rtp') }} disabled={dataDisable} >
                                RTP
                              </Button>
                            </Grid>
                            <Grid item md={6}>
                              <Button className={dataDisable ? 'ach_button disableBtn' : 'ach_button'} onClick={e => { openDirectPayment('ach') }} disabled={dataDisable} >
                                ACH
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>


                        {/* <Box className="attach_file">
                          <FormControl variant="outlined" className='payment_input_choose' fullWidth sx={{ mr: 1 }}>
                            <Typography variant='span' className='attach_pdf'  ><i className="fa-regular fa-file"></i>&ensp; {fileName ? fileName : "Attach PDF File"} &ensp;<i className="fa-solid fa-circle-question"></i></Typography>
                            <OutlinedInput type="file" className='input_file pdf' inputProps={{ accept: '.pdf' }} onChange={handleFile} />
                          </FormControl>
                         { (hideShow || muiApprovalChecked) ? "": <FormControl variant="outlined" className='payment_input_choose' fullWidth sx={{ ml: 1 }} >
                          
                           <Button  className='direct_button' onClick={openDirectPayment} disabled={btnDisabled}>
                           <Typography variant='span'>Direct Deposit &ensp;<i className='fa-solid fa-circle-question'></i> </Typography>
                           </Button>
                          
                          </FormControl>
                           }
                        </Box> */}

                        {/* <Button className="send_payment"  onClick={handelSendPayment} disabled={btnDisabled}>Send Payment</Button> */}
                        <Box sx={{ width: '100%' }}>
                          <LinearProgressWithLabel value={progress} className='linear_modal' />
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className='progress_text color-label-black' variant='span'>Daily limits ($9,932.00 / $200,000.00)</Typography>
                            <Typography className='progress_text ' variant='span'><Link to="#" onClick={openDetailsPoup} >View Details</Link></Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  {/* view details poup */}
                  <Box sx={{ background: '#fff' }} >
                    <BootstrapDialog
                      className='recurring_box'
                      onClose={handleCloseDetails}
                      aria-labelledby="customized-dialog-title"
                      open={openDetails}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle className=''  >
                        <img src={cbLogo} className="modal_icon" />
                        <Typography className='modalhead'>Payment Limit</Typography>
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseDetails}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent>
                        <Box gutterBottom>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: '700', my: 1 }} >Outbound Payment Limits <i className="fa-solid fa-circle-question"></i></FormLabel>
                            <Typography className='progress_text text_md' variant='span'>Payments ($200,000.00)</Typography>
                            <LinearProgressWithLabel value={progress} className='linear_modal ' />
                            <Typography className='progress_text' variant='span'>Daily ($144,794.00 / $200,000.00)</Typography>
                            <LinearProgressWithLabel value={progress} className='linear_modal' />
                            <Typography className='progress_text' variant='span'>Monthly ($492,422.00 / $1,500,000.00)</Typography>
                          </FormControl>

                        </Box>
                        <Box gutterBottom sx={{ my: 4 }}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: '700', my: 1 }}>Inbound Invoice Limits <i className="fa-solid fa-circle-question"></i></FormLabel>
                            <Typography className='progress_text' variant='span'>Invoices ($200,000.00)</Typography>
                            <LinearProgressWithLabel value={progress} className='linear_modal' />
                            <Typography className='progress_text' variant='span'>Daily ($144,794.00 / $200,000.00)</Typography>
                            <LinearProgressWithLabel value={progress} className='linear_modal' />
                            <Typography className='progress_text' variant='span'>Monthly ($492,422.00 / $1,500,000.00)</Typography>
                          </FormControl>
                        </Box>
                        <Box height={10}></Box>
                      </DialogContent>

                    </BootstrapDialog>
                  </Box>



                  <Grid item xs={12} lg={7} xl={7} >
                    <Box className='credit_form img_checkbook rtp-invoice' >
                      <Box sx={{ width: '100%', p: 0 }}>
                        <Box>
                          <StyledTabs value={value} onChange={handleAchChange} aria-label="basic tabs example">
                            <StyledTab label="RTP" {...a11yProps(0)} className='label-invoice tabs_styleer' style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<AccountBalanceIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} />

                            <StyledTab label="ACH" {...a11yProps(0)} className='label-invoice tabs_styleer' style={{ fontSize: '.875rem', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} icon={<PaymentsIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.87)' }} />} />

                          </StyledTabs>

                        </Box>
                        <CustomTabPanel value={value} index={0} className='img-rtp' sx={{ background: '#fff' }} >
                          <Box sx={{ padding: '40px 20px', height: '471px' }}>

                            <ul className='ul-section'>
                              <li>Payor pushes the funds to a pre-designated bank account OR VIP issues instructions to pull the funds from the Payor into
                                a pre-designated bank account. If VIP pulls the funds there may be settlement delay. If Payor pushes funds it will be same day/instant</li>
                              <li>VIP has/verifies funds available in the bank account at the Custodial bank for disbursement</li>
                              <li>VIP transmits transaction details Custodial bank using TCH/Bank</li>
                              <li>Custodial bank initiates the flow of funds through the bank network when the recipient initiates the transfer</li>

                            </ul>
                          </Box>
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1} className='ach-rtp' >
                          <Box sx={{ padding: '40px 20px', height: '471px' }}>
                            <ul className='ul-section'>
                              <li>Payor sends digital checks through VIP's platform</li>
                              <li>VIP transmits transactions daily at 5pm to its custodian bank</li>
                              <li>Custodian bank initiates the flow of funds through the ACH network</li>
                              <li>All parties get ongoing status alerts</li>

                            </ul>
                          </Box>
                        </CustomTabPanel>

                      </Box>

                      {/* <Box className="noneed" >
                        <Box className="overlayBGimags">
                          <img src={cbLogo} />
                        </Box>
                        <Box className="checkbox-box">
                         
                          <h3>{bkName?bkName.toUpperCase():"Bank"}</h3> 
                          <Box className="logoText">
                            <Box className="logoareandText">
                              <Box className="leftmainLogo">
                                <img src={cbLogo} />
                              </Box>
                              <Box className="logoRightText">
                                <Box className="chekbokName"> Inc.</Box>
                                <Typography variant='p'>1500 Fashion Island Boulevard Suite 103</Typography>
                                <Typography variant='p'>San Mateo, CA, 94402</Typography>
                              </Box>
                            </Box>

                            <Box className="logoaraRightTop">
                              <Box className="cheque-number">{checkNo}</Box>
                              <Box className="dateDesopoit"><Typography variant='span'>Date: </Typography><Typography variant='span'>{moment().format('ll')}</Typography> </Box>
                              <Box className="dateDesopoit"><Typography variant='span'>Amount: </Typography> {amount?amount<100000000?currencyFormatter.format(amount, { code: 'USD' }):currencyFormatter.format(99999999.99, { code: 'USD' }):"$0.00"}</Box>
                            </Box>
                          </Box>

                          <Box className="authorizedSign">
                            <Box className="paytotxt">
                              <Box className="labelPaye">Pay:</Box>
                              <Box className="labelText" sx={{maxWidth:'320px',fontSize:'14px'}}>{amount ? amount<100000000? numberToText.convertToText(digit, { language: "en-US" }) + " and "+fraction+ "/100" :numberToText.convertToText(99999999, { language: "en-US" }) + " and 99/100": "Zero and 00/100"} </Box>
                              <Box className="labelPaye Bottom memo_body">To the Order of: {payeeName}</Box>
                              <Box className="labelPaye Bottom memo_body">Memo: {description}</Box>
                            </Box>
                            <Box className="siznixocn">
                             
                              <Typography className='sign'>Signature</Typography>
                              <Typography variant='span' className="authoerizesSIgbn">Authorized Signature</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>



              {/* table section starts */}
              <AllPaymentList data={data} pageCount={pageCount} page={page} handleChange={handleChange} />

            </Box>
            <Box className='inner-section' display={display == false ? "none" : "block"} >
              <Loader display={display} />
            </Box>

          </Box>
        </Box>
      </Box>


      <DirectPaymentModel
        openCLose={directPayment}
        close={handelClose}
        type={typeData}
        email={payeeEmail}
        name={payeeName}
        amount={amount}
        description={description}
        account_id={selectedBank}
        processData={processData}
      />
      <ToastContainer />
      {/* <Loader display={display} /> */}
    </>
  )
}
export default SendPayment