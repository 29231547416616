import { Box, Button, Container, Grid, Typography,MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';

import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import currencyFormatter from 'currency-formatter';
import Loader from './Layouts/Loader.js'
import { Link } from 'react-router-dom';
import axios from 'axios';
import {BootstrapTooltip} from './commonStyle/muiCustomStyle.js'
import CloseIcon from '@mui/icons-material/Close';

import AllPaymentList from './allPaymentList.js';



const Dashboard = () => {
  const [data, setData] = useState([]);
 
  const [openDetailsPoupShow, setOpenDetailsPoupShow] = useState(false);
  const [perPageData, setPerPageData] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [display,setDisplay] = useState(false);
 
  const [page, setPage] = React.useState(1);
  const [account , setAccount] = React.useState("0");
  const handleChange = (event, value) => {
    setPage(value);
  };
  
    const getData = async () => {
     
   setDisplay(true);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://sandbox.checkbook.io/v3/check?page=${page}&per_page=${perPageData}`,
      headers: {
        'accept': 'application/json',
        'Authorization': ' e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
      .then((response) => {
        //console.log("data",response.data.checks[0].number)
        setData(response.data?.checks);
        setPageCount(response.data?.pages)
        //setCheckNo(parseInt(response?.data?.checks[0]?.number)+1)
        setDisplay(false);
      })
      .catch((error) => {
        setDisplay(false);
        console.log(error);
      
      });

  }
  const handleCloseDetailsPopup = (e) => {
    setOpenDetailsPoupShow(false);
  }

  useEffect(() => {
    getData();
  },[page])
 
  /*
useEffect(()=>{
  if(data.length){
    setDisplay(false);
  }
},[data])
 */
  return (

    <>
      <Navbar />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
          <Box>
            <Box className='upper-bluearea'>
              <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
            </Box>

            <Box className='inner-section' display={display==false ? "block":"none"} >
              <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Dashboard</Typography>
              <Box className='incoming-funds mb-30'  >

                <Box className='dollarSection' sx={{ p: '20px 0px 40px 0px' }}>
                  <Grid container>
                    <Grid item xs={12} md={10}>
                      <Typography variant='p' className='fund_head' >Incoming Funds</Typography>

                      <Grid container spacing={2} sx={{ pt: '20px' }}>
                        <Grid item xs={6} lg={3}>
                          <Typography className='dollar_text'>Today</Typography>
                          <Typography variant='p' className='dollar_numbers'>$0.00</Typography>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                          <Typography className='dollar_text'>1 Business Day</Typography>
                          <Typography variant='p' className='dollar_numbers'>$0.00</Typography>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                          <Typography className='dollar_text'>2 Business Days</Typography>
                          <Typography variant='p' className='dollar_numbers'>$0.00</Typography>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                          <Typography className='dollar_text'>3 Business Days</Typography>
                          <Typography variant='p' className='dollar_numbers'>$0.00</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Typography variant='p' className='fund_head' >Prefunded</Typography>
                      <Grid container spacing={2} sx={{ pt: '20px' }}>
                        <Grid item xs={12} lg={12}>
                          <Typography className='dollar_text'>Balance</Typography>
                          <BootstrapTooltip title="Click to see the prefund transaction" placement="left-end">
                            <Typography variant='p' className='dollar_numbers'>$189,601.00</Typography>
                          </BootstrapTooltip>
                        </Grid>
                        {/* <Grid item xs={6} lg={6}>
                          <Typography className='dollar_text'>Account</Typography>
                          <Typography variant='p' className='dollar_numbers'>
                            <BootstrapTooltip title="Click to select a prefunded account" placement="left-end">


                              <FormControl className='bank_input' fullWidth sx={{ p: 0 }}>
                                <Select
                                  className='dashboard_select'
                                  value={account}
                                  sx={{ fontSize: '24px', padding: '0', height: '38px', fontWeight: '300', color: '#0e2e7d' }}
                                >
                                  <MenuItem value="0">****0000</MenuItem>
                                  <MenuItem value="1">****1111</MenuItem>
                                </Select>
                              </FormControl>
                            </BootstrapTooltip>
                          </Typography>
                        </Grid> */}
                      </Grid>

                    </Grid>
                  </Grid>

                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button className='referral-code'>
                    Referral Code
                  </Button>
                </Box> */}
              </Box>

           
                {/* table section starts */}
                <AllPaymentList data={data} pageCount={pageCount}  page={page} handleChange={handleChange} />
            </Box>
            <Box className='inner-section' display={display==false ? "none":"block"} >
               <Loader display={display} />
            </Box>
          </Box>
        </Box>
      </Box>

    
    </>
  )
}

export default Dashboard
