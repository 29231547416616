import { Box, Button, Container, Grid, Typography, FormControl, OutlinedInput } from '@mui/material'
import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import '../assets/css/responsive.css';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import LogoIcon from '../assets/images/dashboard_icon.svg'
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import mainLogo from '../assets/images/main-logo.jpg';
import checkbook from '../assets/images/checkbook.png';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ModalLogo from '../assets/images/viable-logo.jpeg'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {IOSSwitch,BootstrapTooltip} from './commonStyle/muiCustomStyle'
import 'react-toastify/dist/ReactToastify.css';
import AllInvoiceList from './allInvoiceList';
import Loader from './Layouts/Loader.js'
import currencyFormatter from 'currency-formatter';
import moment from 'moment';

const SendInvoice = () => {
    

    const [data, setData] = useState([]);
    const [display,setDisplay] = useState(false);

    const [date, setDate] = React.useState("0");
    const [month, setMonth] = React.useState("0");
    const [year, setYear] = React.useState("0");
    const [duration, setDuration] = React.useState(0);
    const [payeeName, setPayeeName] = useState("");
    const [payeeEmail, setPayeeEmail] = useState("");
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const[recurringDatas,setRecurringDatas] = useState("");
    const [baseSix, setBaseSix] = useState("");
  
    const [fileData, setFileData] = useState([])
    const [fileName, setFileName] = useState("")
    const [fileType, setFileType] = useState("")
    const [fileError, setFileError] = useState(false)
    const [fileErrorMsg, setFileErrorMsg] = useState(false)

    
   const [selectedValue, setSelectedValue] = useState('Monthly');
   const [openDetails, setOpenDetails] = useState(false);
   const [open, setOpen] = useState(false);
   const [muiSwitchChecked, setMuiSwitchChecked] = useState(false);
   const[hideShow,setHideShow] = useState(false);
  const openDetailsPoup = () => {
    setOpenDetails(true);
  }

  const handleCloseDetails = () => {
    setOpenDetails(false);
  }

  const handlePayeeName = (e) => {
    setPayeeName(e.target.value);
  }

  const hadlePayeeEmail = (e) => {
    setPayeeEmail(e.target.value);
  }  
  
  const handleAmount = (e) => {
    if(e.target.value >=0 && e.target.value <=200000){
    setAmount(e.target.value);
    }
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
  }

  const handleClose = () => {
    setOpen(false);
  };

 const [dataDisable,setDataDisable] = useState(true); 

useEffect(()=>{
 // console.log("testt",payeeEmail,payeeName,amount)
  if(payeeEmail !="" && payeeName !="" && amount !="" && validateEmail(payeeEmail) && description){
    setDataDisable(false);
  }else{
    setDataDisable(true);
  }
},[payeeEmail,payeeName,amount,description]);

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkEnteredAnount = () =>{
    if(amount > 200000.00 ){
        setAmount("");
        toast.error("Please enter the amount less than or equal to $200000.00")
    }
}
const onNumerInputKeyDown = (event) => {
  if(event.key === "e") event.preventDefault();
  if(event.key === "-") event.preventDefault();
}


    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    
    const [perPageData, setPerPageData] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const getData = async () => {
       
     setDisplay(true);
  
      let config = {
        method: 'get',
        maxBodyLength: Infinity,  
        url: `https://sandbox.checkbook.io/v3/invoice?page=${page}&per_page=${perPageData}`,
        headers: {
          'accept': 'application/json',
          'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
          'Content-Type': 'application/json'
        }
      };
  
      axios.request(config)
        .then((response) => {
          //console.log("data invoice",response.data)
          setData(response.data?.invoices);
          setPageCount(response.data?.pages)
        setDisplay(false);
        })
        .catch((error) => {
          setDisplay(false);
          console.log(error);
        
        });
  
    }
    useEffect(() => {
        getData();
      
      }, []);
    
  
    useEffect(() => {
      getData();
    },[page])
  

    const handleSave = () => {
        setOpen(false);
       
       //let reccuringData=  selectedValue+", "+ year+"-"+month+"-"+date +","+ duration
       let dates = "";
       if(year !="0" && month !="0" && date !="0"){
        dates="from " +year+"-"+month+"-"+date;
       }else{
        setYear(0);setMonth(0);setDate(0);
       }
       let dur="";
       if(duration!=0){
          dur=duration + " invoices" 
       }else{
        setDuration(0);
       }
        
       let reccuringData=  selectedValue+ (dates!=""?","+dates:"") + (dur!=""?","+dur:"");
       setRecurringDatas(reccuringData);
    
      };

      const handleDuration = (e) => {
        setDuration(e.target.value);
      };

      const handleDate = (e) => {
        setDate(e.target.value);
      };
      const handleMonth = (e) => {
        setMonth(e.target.value);
      };
      const handleYear = (e) => {
        setYear(e.target.value);
      };

      const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
      };

      const handleClickOpen = (event) => {
        // console.log("event.target.checked",event.target.checked)
          setMuiSwitchChecked(event.target.checked);
          setOpen(event.target.checked);
          setHideShow(event.target.checked)
          setRecurringDatas('Monthly');
        };
  
        if(hideShow){
            data["interval"] = selectedValue;
            if(year !="0" && month !="0" && date !="0"){
             data['start_date']= year+"-"+date+"-"+month;
            }
            if(duration!=""){
             data['duration']= duration;
            }
         }    
     

         const getBase64 = file => {
            return new Promise(resolve => {
              let fileInfo;
              let baseURL = "";
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
              };
            });
          };

          useEffect(() => {
            if (fileData.length > 0) {
              getBase64(fileData[0]).then(result => {
                setBaseSix(result);
              });
            }
          }, [fileData]);

   
  const allowedTypes = ["application/pdf"];
  const MAX_FILE_SIZE_IMAGE = 7168 // 7MB

  const handleFile = (e) => {
    const selectedFile = e.target.files;
    setFileError(false)
    setFileErrorMsg("");
    setFileData(selectedFile)
    let file_name = selectedFile[0]?.name;
    let file_type = selectedFile[0]?.type;
    setFileName(file_name)
    if (file_type === "application/pdf") {
      setFileType("pdf")
    }
    else {
      setFileType("")
    }
    if (selectedFile.length && !allowedTypes.includes(selectedFile[0]?.type)) {
      setFileError(true)
      setFileErrorMsg("Only PDF images are allowed.");
    }
    if (selectedFile.length && (selectedFile[0].size / 1024) > MAX_FILE_SIZE_IMAGE) {
      setFileErrorMsg("File size is greater than 2 MB");
      setFileError(true)
    }
  }


  
  const handelSendInvoice = () => {
    setDisplay(true);

    let data = {
      amount: amount,
      description: description,
      name: payeeName,
      recipient: payeeEmail,

    }
    if (baseSix) {
      data['attachment'] = {
        content: baseSix.replace("data:application/pdf;base64,", ""),
        filename: 'attachment.pdf'
      }
    }
 
    if (hideShow) {
      data["interval"] = selectedValue;
      if (year != "0" && month != "0" && date != "0") {
        data['start_date'] = year + "-" + date + "-" + month;
      }
      if (duration != 0) {
        data['duration'] = duration;
      }
    }


    let dataMain = JSON.stringify(data);

    let config = {
      method: 'post',
      url: 'https://sandbox.checkbook.io/v3/invoice',
      headers: {
        'accept': 'application/json',
        'Authorization': 'e4e5edce34184cfbbf89a7ee164c65d1:xhyar7pDJNJv8av6LZhbEEZJq37ogR',
        'Content-Type': 'application/json'
      },
      data: dataMain
    };

    axios.request(config)
      .then((response) => {
        let res = response.data
        if (res.status) {
          getData();
          toast.success("Added Sucessfully.");
          setDisplay(false);
          restAll()
        }
      })
      .catch((error) => {
        setDisplay(false);
        //    console.log(error);
        toast.error(error?.response?.data?.error);

      });


  }

  const restAll = () => {
    setPayeeEmail("");
    setPayeeName("");
    setAmount("");
    setDescription("");
    setFileData([]);
    setFileName("");
    setBaseSix("");
    setDate("0");
    setMonth("0");
    setYear("0");
    setDuration(0);
    setRecurringDatas("")
    setMuiSwitchChecked(false);
  }

  const openEditModel =() =>{
    setOpen(true) 
 }

    return (
        <>
            <Navbar />
            <Box height={30} />
            <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
                    <Box>
                        <Box className='upper-bluearea'>
                        <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
                        </Box>
                        <Box className='inner-section' display={display==false ? "block":"none"}>
                            <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Send An Invoice</Typography>
                            <Box className='payment_form_area'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={5} md={5} xl={5} >
                                        <Box className='payment_form many_payment'>
                                            <Box>

                                                <Box>
                                                    <Box className="icon_input">
                                                        <Typography variant="div" className='icon_style'>
                                                            <i className="fa-regular fa-user "></i>
                                                        </Typography>
                                                        <FormControl variant="outlined" className='payment_input' fullWidth >
                                                            <OutlinedInput placeholder="Payor's Name*" value={payeeName} onChange={handlePayeeName} />
                                                        </FormControl>
                                                    </Box>

                                                    <Box className="icon_input">
                                                        <Typography variant="div" className='icon_style'>
                                                            <i className="fa-regular fa-envelope"></i>
                                                        </Typography>
                                                        <FormControl variant="outlined" className='payment_input' fullWidth>
                                                            <OutlinedInput placeholder="Payor's email*" value={payeeEmail} onChange={hadlePayeeEmail} />
                                                        </FormControl>
                                                    </Box>

                                                    <Box className="icon_input">
                                                        <Typography variant="div" className='icon_style'>
                                                            <i className="fa-solid fa-dollar-sign"></i>
                                                        </Typography>
                                                        <FormControl variant="outlined" className='payment_input' fullWidth>
                                                            <OutlinedInput type="number" placeholder='Amount*'   value={amount} onChange={handleAmount} onBlur={checkEnteredAnount} onKeyDown={onNumerInputKeyDown} />
                                                        </FormControl>
                                                    </Box>

                                                    <Box className="icon_input">
                                                        <Typography variant="div" className='icon_style'>
                                                            <i className="fa-solid fa-t"></i>
                                                        </Typography>
                                                        <FormControl variant="outlined" className='payment_input' fullWidth>
                                                            <OutlinedInput placeholder='Description*' value={description} onChange={handleDescription} />
                                                        </FormControl>
                                                    </Box>
                                                </Box>

                                                <Box className='payment_toggle' sx={{ py: 1 }}>
                                                    <Typography variant='p' component='p'>Recurring
                                                    </Typography>
                                                    <FormControlLabel
                                                     onChange={handleClickOpen}
                                                     checked={muiSwitchChecked}
                                                        control={<IOSSwitch sx={{ marginLeft: '30px' }} />}
                                                    />
 <Typography sx={{ textAlign: 'end',fontSize:'14px!important' }}> {(hideShow) ?  recurringDatas :"" } {(hideShow) ? <i className='fa fa-edit' onClick={openEditModel}></i>:""}  </Typography>
                                                </Box>


                                                <Box className="attach_file">
                                                    <FormControl variant="outlined" className='payment_input_choose' fullWidth sx={{ mr: 1 }}>
                                                        
                                                        <Typography variant='span' className='attach_pdf' sx={{ width: '150px' }}  ><i className="fa-regular fa-file"></i>&ensp; {fileName ? fileName : "Attach PDF File"} &ensp;<i className="fa-solid fa-circle-question"></i></Typography>
                                                        <OutlinedInput type="file" className='input_file pdf' inputProps={{ accept: '.pdf' }} onChange={handleFile} />
                                                    </FormControl>
                                                    <Button className={dataDisable?'rtp_button disableBtn':'rtp_button'} sx={{ my: 0 }} onClick={handelSendInvoice} disabled={dataDisable} >Send Invoice</Button>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={7} lg={7} xl={7} >
                                        <Box className='payment_form many_payment send-invoice-body' >
                                            <Box sx={{ borderBottom: '1px solid #d9d9d9' }}>
                                                <Typography variant='h4' sx={{ py: 1, textAlign: 'end'}}>Invoice</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between',py:2 }}>
                                                <Box className='invoice-checkbook'>
                                                    <Typography className='inc' variant='h5'>Viable Inc.</Typography>
                                                    <Typography className='inc-date'>Invoice date: {moment().format('LL')}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography  className='inc' variant='h5' sx={{textAlign:'right'}}>Invoice to</Typography>
                                                    <Typography sx={{textAlign:'right'}} className='inc-date'>{payeeName}</Typography>
                                                    <Typography sx={{textAlign:'right'}} className='inc-date'>{payeeEmail}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{padding:'30px 0 20px 0'}}>
                                                <Box className='invoice-descript' sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#E0EEFD' }}>
                                                    <Box>
                                                        <Typography variant='h5' className='inc-body'>Description</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant='h5' className='inc-body'>Amount</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                            <Box className='invoice-testing' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Box>
                                                        <Typography variant='p' className='inc-date'>{description}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant='p' className='inc-date'>{currencyFormatter.format(amount, { code: 'USD' })}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>

                            {/* table section starts */}

                           <AllInvoiceList data={data} pageCount={pageCount}  page={page} handleChange={handleChange} />
                        </Box>
                        <Box className='inner-section' display={display==false ? "none":"block"} >
                             <Loader display={display} />
                        </Box>

                    </Box>
                </Box>
            </Box>


            <Box sx={{ background: '#fff' }} >
                          <Dialog
                            className='recurring_box'
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            fullWidth
                            maxWidth="sm"
                          >
                            <DialogTitle className=''  >
                              <img src={cbLogo} className="modal_icon" />
                              <Typography className='modalhead'>Recurring invoice details</Typography>
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <DialogContent >
                              
                              <Box gutterBottom>
                              <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: 'fit-content',
                                }}>
                                <FormControl>
                                  <FormLabel id="demo-row-radio-buttons-group-label">Interval <i className="fa-solid fa-circle-question"></i></FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value="Weekly"
                                      control={<Radio />}
                                      label="Weekly"
                                      className='recurring_radio'
                                      checked={selectedValue === 'Weekly'}
                                      onChange={handleRadioChange}
                                    />
                                    <FormControlLabel
                                      value="Monthly"
                                      control={<Radio />}
                                      label="Monthly"
                                      className='recurring_radio'
                                      checked={selectedValue === 'Monthly'}
                                      onChange={handleRadioChange}
                                    />

                                  </RadioGroup>
                                </FormControl>
                              </Box>

                              <FormLabel>Start date (optional) <i className="fa-solid fa-circle-question"></i></FormLabel>
                              <Grid container className='date_input '>
                                <Grid md={4}>
                                  <FormControl className='bank_input select-input-height' fullWidth>
                                    <Select
                                      value={month}
                                      onChange={handleMonth}
                                      className='bank_select'
                                      sx={{ margin: '5px 10px 20px 0',height:'42px' }}

                                    >
                                      <MenuItem disabled value="0" >MM</MenuItem>
                                      {[...Array(12)].map((x, i) =>
                                              
                                        <MenuItem value={i+1} key={i+1}>{i+1}</MenuItem>
                                        )}
                                      

                                    </Select>
                                  </FormControl>
                                </Grid>

                                <Grid md={4}>
                                  <FormControl className='bank_input' fullWidth>
                                    <Select
                                      value={date}
                                      onChange={handleDate}
                                      className='bank_select'
                                      sx={{ margin: '5px 10px 20px 0',height:'42px'}}
                                    >
                                      <MenuItem disabled value="0" key ="0" >DD</MenuItem>
                                      {[...Array(31)].map((x, i) =>
                                              
                                       <MenuItem value={i+1} key={i+1}>{i+1}</MenuItem>
                                       )}
                                      
                                      
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid md={4}>
                                  <FormControl className='bank_input' fullWidth>
                                    <Select
                                      value={year}
                                      onChange={handleYear}
                                      className='bank_select'
                                      sx={{ margin: '5px 0px 20px 0px',height:'42px' }}
                                    >
                                      <MenuItem disabled value="0">YYYY</MenuItem>
                                      {[...Array(5)].map((x, i) =>
                                              
                                              <MenuItem value={i+2022} key={i}>{i+2022}</MenuItem>
                                       )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>

                              <Box>
                                <FormControl className='bank_input' fullWidth>
                                  <FormLabel id="demo-row-radio-buttons-group-label">Duration (optional) <i className="fa-solid fa-circle-question"></i></FormLabel>
                                  <Select
                                    value={duration}
                                    onChange={handleDuration}
                                    className='bank_select'
                                    sx={{ margin: '5px 0px 0px 0px',height:'42px' }}
                                  >
                                    <MenuItem  value="0"> Indefinite</MenuItem>
                                    {[...Array(52)].map((x, i) =>
                                              
                                            <MenuItem value={i+1} key={i}>{i+1}</MenuItem>
                                       )}
                                    
                                  </Select>
                                </FormControl>
                              </Box>
                              </Box>
                              <Box height={30}>

                              </Box>
                            </DialogContent>
                            <DialogActions>
                              <Button autoFocus onClick={handleSave} className='recurring_modal_btn'  sx={{margin:'5px 10px 10px 0px'}}>
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>

            <ToastContainer />
        </>
    )
}
export default SendInvoice