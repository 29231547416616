import { Box, Button, Container, Grid, Typography, FormControl, OutlinedInput } from '@mui/material'
import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import '../assets/css/responsive.css';
import Sidenav from './Layouts/Sidenav';
import Navbar from './Layouts/Navbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import LogoIcon from '../assets/images/dashboard_icon.svg'
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import mainLogo from '../assets/images/main-logo.jpg';
import checkbook from '../assets/images/checkbook.png';
import cbLogo from '../assets/images/pb-logo-removebg-preview.png';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ModalLogo from '../assets/images/viable-logo.jpeg'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IOSSwitch,BootstrapTooltip } from './commonStyle/muiCustomStyle';


const Reports = () => {
    const [bank, setBank] = React.useState("");
    const handleBank = (e) => {
        setBank(e.target.value);
    };


    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Navbar />
            <Box height={30} />
            <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" className='main-section' sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
                    <Box>
                        <Box className='upper-bluearea'>
                            <Typography component='p' variat='p'><strong>VIP</strong> Payment Experience</Typography>
                        </Box>
                        <Box className='inner-section'>
                            <Typography variant='p' component='p' sx={{ color: '#0e2e7d', fontWeight: '500', pb: '20px' }}>Reports</Typography>


                            {/* table section starts */}

                            <Box className='forever_table ' >
                                <Box className='dashboard_table'>
                                    <Box sx={{ width: '100%' }}>
                                        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }} >
                                            <Toolbar sx={{ justifyContent: 'space-between', display: 'flex', }} className='upperheader_forever'>
                                                <Box sx={{ display: 'flex', }}>
                                                    <Typography
                                                        sx={{ borderRight:'none' }}
                                                        variant="p"
                                                        id="tableTitle"
                                                        component="div"
                                                        className='table-head-p no-line no-report-line'
                                                    >
                                                        <TextField placeholder='Search' className='searchHeadTable' />
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ textAlign: 'end', display: 'flex', justifyContent: 'end', }}>
                                                    <Typography
                                                        sx={{ px: "20px" }}
                                                        variant="p"
                                                        id="tableTitle"
                                                        component="div"
                                                        className='table-head-p'
                                                    >
                                                        <SearchIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                                    </Typography>


                                                    <BootstrapTooltip title="Clear">
                                                        <Typography
                                                            sx={{ pl: "20px", borderRight: 'none' }}
                                                            variant="p"
                                                            id="tableTitle"
                                                            component="div"
                                                            className='table-head-p no-line'
                                                        >
                                                            <CloseOutlinedIcon sx={{ color: '#0e2e7d', fontSize: '22px' }} />
                                                        </Typography></BootstrapTooltip>
                                                </Box>
                                            </Toolbar>

                                            <TableContainer className='' sx={{ padding: '10px  30px' }}>
                                              
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    className='table-head-color'
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox

                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>

                                                            <TableCell
                                                            >
                                                                Type
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Date
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                              Report ID
                                                            </TableCell>
                                                            <TableCell
                                                            >
                                                                Status
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>

                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    className='checkbox_table'
                                                                />
                                                            </TableCell>
                                                            <TableCell>	Dashboard</TableCell>
                                                            <TableCell>	2/11/24 01:12   </TableCell>
                                                            <TableCell>123434sdfdsf88778346969ddsdfg    	</TableCell>
                                                            <TableCell><Link to={"#"} className='link-style'> Download</Link></TableCell>

                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Pagination count={10} page={page} onChange={handleChange} className='pagination_dashboard' />
                                        </Paper>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <ToastContainer />
        </>
    )
}
export default Reports